<script setup lang="ts">
import { ref, watch } from 'vue';
// components
import Input from '@/components/kit/Input.vue';
import { useAsyncPlaceholderAllListStore } from '../store/FaqPlaceholderStore';


const tableStore = useAsyncPlaceholderAllListStore();
// tableStore;

const key = ref<string>('');
const value = ref<string>('');

watch([key, value], ()=>{
    // tableStore.searchBy
    tableStore.filterBy(1, [key.value]);
    tableStore.filterBy(2, [value.value]);
})
</script>

<template>
  <tbody class="cmi-deals-all-filters">
    <tr>
      <td colspan="1">
        Фильтры:
      </td>
      <td class="faq__table-tag-filter">
        <span class="mdi mdi-magnify"></span>
        <Input
          v-model="key"
          type="search"
        />
      </td>
      <td class="faq__table-tag-filter">
        <span class="mdi mdi-magnify"></span>
        <Input
          v-model="value"
          type="search"
        />
      </td>
      <td />
      <td />
    </tr>
  </tbody>
  <tbody
    v-if="tableStore.loading"
    class="cmi-deals-all-filters"
  >
    <tr>
      <td colspan="8">
        Загрузка:
      </td>
    </tr>
  </tbody>
</template>