<script setup lang="ts">
import { TCategoryData } from '../store';

const props = defineProps<{ rowData: TCategoryData }>();
const { rowData } = props;

</script>

<template>
  <td>
    <div v-if="!rowData.isEditing" class="d-flex gap-2 align-items-center">
      <div class="row container-row-table">
        <span v-text="rowData.description" />
        <span class="gray" v-text="'Черновики: ' + rowData.draftCount"/>
        <span class="gray" v-text="'Экспорт: ' + rowData.publishedCount"/>
      </div>
    </div>
    
    <div v-else class="d-flex gap-2 align-items-center">
      <input 
        class="category-input-table"
        v-model="rowData.description"
      />
    </div>
  </td>
</template>

<style>
.category-input-table {
  width: 80%;
  padding: 6px;
  border: solid 1px #a8a8a8;
  border-radius: 4px;
  outline: none; 
}

.category-input-table:focus {
  border-color: #007bff; 
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.is-invalid {
  border: 1px solid red;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}

.text-danger {
  color: red;
  font-size: 0.875rem;
}

.gray{
  color: gray;
}

.container-row-table{
  row-gap: 5px;
}
</style>