import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dot-indicator sm bg-primary"
}


import { ITableCellProps } from '@/components/Table/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'DotIndicator',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;
const notViewed = props.rowData.faq_question_viewed.data?.id;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", null, [
    (!_unref(notViewed))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true)
  ]))
}
}

})