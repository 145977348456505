import { ref } from 'vue';

export type NotificationType = 'success' | 'error';

export interface INotification {
  type: NotificationType;
  message: string;
  isVisible: boolean;
  duration: number;
}

const notificationQueue = ref<INotification[]>([]); 
let isNotificationActive = ref(false); 

export function showNotification(type: NotificationType, message: string, duration: number) {
  notificationQueue.value.push({
    type,
    message,
    isVisible: true,
    duration
  });

  if (!isNotificationActive.value) {
    displayNextNotification();
  }
}

function displayNextNotification() {
  if (notificationQueue.value.length > 0) {
    isNotificationActive.value = true;
    const currentNotification = notificationQueue.value[0]; 

    setTimeout(() => {
      currentNotification.isVisible = false; 
      setTimeout(() => {
        notificationQueue.value.shift(); 
        isNotificationActive.value = false;
        displayNextNotification(); 
      }, 200); 
    }, currentNotification.duration); 
  }
}

export function useNotification() {
  return {
    notificationQueue
  };
}
