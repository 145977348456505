<script setup lang="ts">
import { ref, computed } from 'vue';
import { TJsonExtensionProps } from 'litsystems-vue-json-editor-dev/src';
import { useDictionaryEditNicknameStore } from '../store';
import { NameKeys, nameKeysDictionary } from '../types';
import CardTitle from '@/components/kit/CardTitle.vue';
import Row from "@/components/kit/Row.vue";

const props = defineProps<TJsonExtensionProps>();
const editStore = useDictionaryEditNicknameStore();

const inputValue = ref("");
const tagAlreadyDictionary = ref("");

const filteredTags = computed(() => {
  const normalizeText = (text: string) => text.trim().replace(/\s+/g, ' ').toLowerCase();

  const searchWords = inputValue.value
    .split(',')
    .map(word => normalizeText(word))
    .filter(word => word);

  if (!searchWords.length) {
    return [];
  }

  return editStore.dictionary.words.filter((tag: string) =>
    searchWords.some(searchWord => normalizeText(tag).includes(searchWord))
  );
});

const labelName = computed(() => {
  return nameKeysDictionary[props.propKey as keyof NameKeys] || props.propKey;
});

const updateStore = () => {
  const jsonStore = props.useStore();
  jsonStore.setValueByKeys(
    [...props.parentKeys, props.propKey].slice(1),
    [...editStore.dictionary.words]
  );
};

const addTags = (tags: string) => {
  const normalizeText = (text: string) => text.trim().replace(/\s+/g, ' ').toLowerCase();

  const newTags = tags
    .split(',')
    .map(tag => normalizeText(tag))
    .filter((tag, index, self) =>
      tag && self.indexOf(tag) === index
    );

  const existingTags = newTags.filter(tag =>
    editStore.dictionary.words.some((existingTag: string) => normalizeText(existingTag) === tag)
  );

  const uniqueNewTags = newTags.filter(tag => !existingTags.includes(tag));

  if (uniqueNewTags.length) {
    editStore.isUpdated = true;
    editStore.dictionary.words.push(...uniqueNewTags);
    updateStore();
  }

  if (existingTags.length) {
    tagAlreadyDictionary.value = `Данные теги уже есть в словаре`;
  } else {
    tagAlreadyDictionary.value = "";
  }

  inputValue.value = existingTags.join(', ');
};

const handleInput = (e: KeyboardEvent) => {
  const input = e.target as HTMLTextAreaElement;

  if (e.key === 'Backspace' && !input.value.trim() && editStore.dictionary.words.length) {
    editStore.isUpdated = true;
    editStore.dictionary.words.pop();
    updateStore();
    return;
  }

  if (e.key === 'Enter') {
    e.preventDefault();
    e.stopPropagation();
    addTags(input.value);
  }
};

const addTag = () => {
  if (inputValue.value.trim()) {
    addTags(inputValue.value);
  }
};

const removeTag = (index: number) => {
  editStore.isUpdated = true;
  editStore.dictionary.words.splice(index, 1);
  updateStore();
};

const removeFilteredTag = (tag: string) => {
  const index = editStore.dictionary.words.indexOf(tag);
  if (index !== -1) {
    removeTag(index);
  }
};

const sentence = ref("");
const highlightedSentence = ref<string>("");
const showCheckField = ref(false);
const isChecked = ref(false);

const checkSentence = () => {
  let sentenceText = sentence.value.trim().toLowerCase().replace(/\s+/g, ' ');

  if (!sentenceText) {
    highlightedSentence.value = "";
    isChecked.value = false;
    return;
  }

  const uniqueWords = Array.from(editStore.dictionary.words);

  let highlightedText = sentenceText;

  uniqueWords.forEach((word) => {
    let startIndex = 0;

    while ((startIndex = highlightedText.toLowerCase().indexOf(word, startIndex)) !== -1) {
      const beforeWord = highlightedText.slice(0, startIndex);
      const wordToHighlight = highlightedText.slice(startIndex, startIndex + word.length);
      const afterWord = highlightedText.slice(startIndex + word.length);

      highlightedText = `${beforeWord}<span class='highlight'>${wordToHighlight}</span>${afterWord}`;

      startIndex += word.length + `<span class='highlight'></span>`.length;
    }
  });

  highlightedSentence.value = highlightedText;
  isChecked.value = true;
};

const toggleCheckField = () => {
  showCheckField.value = !showCheckField.value;
  isChecked.value = false;
};
</script>


<template>
  <Row class="tab-content tab-transparent-content">
    <div class="row">
      <p>Название словаря:</p>
      <CardTitle>Проверка спама в имени</CardTitle>
    </div>
    <div class="col-lg-6 height-textarea margin-both">
      <label class="key-label margin-both">
        Добавление и поиск запрещенных слов в никнейме
      </label>
      <textarea class="ban-word__form"
        placeholder="Добавьте или найдите теги (через запятую, нажмите Enter для добавления, либо на кнопку ниже)"
        v-model="inputValue" @keydown="handleInput"></textarea>
      <div v-if="tagAlreadyDictionary" class="error-message">
        {{ tagAlreadyDictionary }}
      </div>
      <button class="btn btn-success margin-top" @click="addTag" :disabled="!inputValue.trim()">
        Добавить тег
      </button>
      <div>
        <label v-if="inputValue" class="key-label margin-top">Встречающиеся слова в словаре</label>
        <div v-if="inputValue">
          <div v-if="filteredTags.length" class="filtered-tags">
            <div v-for="(tag, index) in filteredTags" :key="index" class="filtered-tag">
              <span>{{ tag }}</span>
              <a href="#" @click.prevent="removeFilteredTag(tag)">×</a>
            </div>
          </div>
          <div v-else class="no-matches">
            Совпадений нет
          </div>
        </div>
      </div>

      <div v-if="showCheckField" class="check-sentence">
        <textarea v-model="sentence" class="ban-word__form" placeholder="Введите предложение для проверки"></textarea>
        <div v-if="isChecked" v-html="highlightedSentence" class="highlighted-sentence"></div>
        <button class="btn btn-primary margin-sides margin-top" @click="checkSentence">Проверить</button>
        <button class="btn btn-primary margin-top" @click="toggleCheckField">Скрыть проверку</button>
      </div>

      <button v-else class="btn btn-primary margin-top margin" @click="toggleCheckField">
        Проверить сообщение
      </button>
    </div>

    <div class="col-lg-6">
      <label class="key-label margin-top">
        {{ labelName }}
      </label>

      <div class="tagsinput margin-both">
        <div class="tags-container">
          <div v-for="(tag, index) in editStore.dictionary.words" :key="index" class="tag">
            <span>{{ tag }}</span>
            <a href="#" @click.prevent="removeTag(index)">×</a>
          </div>
        </div>
      </div>
    </div>
  </Row>
</template>