import { getAuthKey } from '@/service/authKey';
import { config } from '@/config';


const {
	host,
	headerAuthKey,
} = config.bff;


export type TBffResponseError = {
	error: string;
	requestId: string;
	code?: number;
}

type THeaders = Record<string, string>;

export const postWithoutAuth = async <TReq, TRes>(endpoint: string, body: TReq, headers?: THeaders): Promise<TRes> => {

	return new Promise((resolve, reject) => {
		fetch(`${host}${endpoint}`, {
			method: 'POST',
			headers: {
				'accept': 'application/json',
				'Content-Type': 'application/json',
				'x-auth-request': headerAuthKey,
				...headers,
			},
			body: JSON.stringify(body),
		})
			.then(async res => {
				const requestId = res.headers.get('X-Request-Id') || '';

				if (res.status === 200) {
					const responseText = await res.text();
					try {
						const data = JSON.parse(responseText) as TRes;
						resolve(data);
					}
					catch (e) {
						reject({
							error: 'invalid response data',
							code: res.status,
							requestId,
						})
					}
				}
				else {
					reject({
						error: 'invalid response status',
						code: res.status,
						requestId,
					});
				}
			})
			.catch(err => {
				reject({
					error: err?.message || '',
					requestId: '',
					code: 0
				});
			})
	})
}

export const putWithoutAuth = async <TReq, TRes>(endpoint: string, body: TReq, headers?: THeaders): Promise<TRes> => {

	return new Promise((resolve, reject) => {
		fetch(`${host}${endpoint}`, {
			method: 'PUT',
			headers: {
				'accept': 'application/json',
				'Content-Type': 'application/json',
				'x-auth-request': headerAuthKey,
				...headers,
			},
			body: JSON.stringify(body),
		})
			.then(async res => {
				const requestId = res.headers.get('X-Request-Id') || '';

				if (res.status === 200) {
					const responseText = await res.text();
					try {
						const data = JSON.parse(responseText) as TRes;
						resolve(data);
					}
					catch (e) {
						reject({
							error: 'invalid response data',
							code: res.status,
							requestId,
						})
					}
				}
				else {
					reject({
						error: 'invalid response status',
						code: res.status,
						requestId,
					});
				}
			})
			.catch(err => {
				reject({
					error: err?.message || '',
					requestId: '',
					code: 0
				});
			})
	})
}

export const getWithoutAuth = async <TRes>(endpoint: string, headers?: THeaders): Promise<TRes> => {
	return new Promise((resolve, reject) => {
		fetch(`${host}${endpoint}`, {
			headers: {
				'accept': 'application/json',
				'Content-Type': 'application/json',
				'x-auth-request': headerAuthKey,
				...headers,
			},
		})
			.then(async res => {
				const requestId = res.headers.get('X-Request-Id') || '';

				if (res.status === 200) {
					const responseText = await res.text();
					try {
						const data = JSON.parse(responseText) as TRes;
						resolve(data);
					}
					catch (e) {
						reject({
							error: 'invalid response data',
							code: res.status,
							requestId,
						})
					}
				}
				else {
					reject({
						error: 'invalid response status',
						code: res.status,
						requestId,
					});
				}
			})
			.catch(err => {
				reject({
					error: err?.message || '',
					requestId: '',
					code: 0
				});
			})
	})
}


export const get = <TReq>(endpoint: string): Promise<TReq> => {
	const userAuthKey = getAuthKey();

	return getWithoutAuth(endpoint, {
		'auth-key': userAuthKey,
	})
}

export const put = <TReq, TRes>(endpoint: string, data: TReq): Promise<TReq> => {
	const userAuthKey = getAuthKey();

	return putWithoutAuth(endpoint, data, {
		'auth-key': userAuthKey,
	})
}

export const downloadCmiPdf = (antrag: string) => {
	const userAuthKey = getAuthKey();
	return fetch('/api/formulars/download-cmi-tk-pdf/' + antrag, {
		headers: {
			'x-auth-request': headerAuthKey,
			'auth-key': userAuthKey,
		}
	})
}

export const post = async <TReq, TRes>(
  endpoint: string,
  data: TReq
): Promise<TRes> => {
  const userAuthKey = getAuthKey();
  return postWithoutAuth<TReq, TRes>(endpoint, data, {
    'auth-key': userAuthKey,
  });
};


export const deleteWithoutAuth = async <TRes>(endpoint: string, headers?: THeaders): Promise<TRes> => {
	return new Promise((resolve, reject) => {
	 fetch(`${host}${endpoint}`, {
		method: 'delete',
		headers: {
		 'accept': 'application/json',
		 'Content-Type': 'application/json',
		 'x-auth-request': headerAuthKey,
		 ...headers,
		},
	 })
		.then(async res => {
		 const requestId = res.headers.get('X-Request-Id') || '';
 
		 if (res.status === 200) {
			const responseText = await res.text();
			try {
			 const data = JSON.parse(responseText) as TRes;
			 resolve(data);
			}
			catch (e) {
			 reject({
				error: 'invalid response data',
				code: res.status,
				requestId,
			 })
			}
		 }
		 else {
			reject({
			 error: 'invalid response status',
			 code: res.status,
			 requestId,
			});
		 }
		})
		.catch(err => {
		 reject({
			error: err?.message || '',
			requestId: '',
			code: 0
		 });
		})
	})
 }

 export const del = <TRes>(endpoint: string): Promise<TRes> => {
	const userAuthKey = getAuthKey();
 
	return deleteWithoutAuth(endpoint, {
	 'auth-key': userAuthKey,
	})
 }


export const postFormDataWithoutAuth = async <TRes>(endpoint: string, data: FormData, headers?: THeaders): Promise<TRes> => {
	return new Promise((resolve, reject) => {
		fetch(`${host}${endpoint}`, {
			method: 'POST',
			headers: {
				'x-auth-request': headerAuthKey,
				...headers,
			},
			body: data,
		})
			.then(async (res) => {
				const requestId = res.headers.get('X-Request-Id') || '';

				if (res.status === 200) {
					const responseText = await res.text();
					try {
						const data = JSON.parse(responseText) as TRes;
						resolve(data);
					} catch (e) {
						reject({
							error: 'invalid response data',
							code: res.status,
							requestId,
						});
					}
				} else {
					reject({
						error: 'invalid response status',
						code: res.status,
						requestId,
					});
				}
			})
			.catch((err) => {
				reject({
					error: err?.message || '',
					requestId: '',
					code: 0,
				});
			});
	});
};

export const postFormData = async <TRes>(endpoint: string, data: FormData): Promise<TRes> => {
	const userAuthKey = getAuthKey();
	return postFormDataWithoutAuth(endpoint, data, {
		'auth-key': userAuthKey,
	});
};
  