import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { isUserCanEditDictionary, isUserCanViewDictionary } from '@/store';
import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import { useRoute } from 'vue-router';
import { TJsonUseStore } from 'litsystems-vue-json-editor-dev/src';
import JsonViewer from 'litsystems-vue-json-editor-dev/src/JsonViewer.vue';
import { useDictionaryEditItemStore } from './store/dictionaryItemStore';
import InputTagDictionaryRegulars from './components/InputTagDictionaryRegulars.vue';
import InputTagDictionaryBanWord from './components/InputTagDictionaryBanWord.vue';
import InputTagDictionaryBanWordNickname from './components/InputTagDictionaryBanWordNickname.vue';
import { useDictionaryEditNicknameStore } from './store';

// store

export default /*@__PURE__*/_defineComponent({
  __name: 'DictionaryEdit',
  setup(__props) {

const store = useDictionaryEditItemStore();
const storeNickname = useDictionaryEditNicknameStore();
const dealId = ref<number>(NaN);

var useDictionaryStore: TJsonUseStore['useStore'];
var useDictionaryStoreNickname: TJsonUseStore['useStore'];

const getUseStoreCb = (useStore: TJsonUseStore['useStore']) => {
  useDictionaryStore = useStore;

  const jsonStore = useStore();

  // устанавливаем расширения
  jsonStore.addExtensionComponent('dictionary-ban-word', InputTagDictionaryBanWord);
  jsonStore.addExtensionComponent('dictionary-input', InputTagDictionaryRegulars);

  jsonStore.updateExtensions({
    key: {
      'words': ['dictionary-ban-word'],
      'regulars': ['dictionary-input'],
    },
  });

  // устанавливаем классы
  jsonStore.updateClassNames({
    row: {
      key: {
        'regulars': "--requlars-container"
      }
    },
    viewer: {
      key: {
        'words': "--hidden-row",
        'regulars': "--hidden-row",
      }
    }
  });

  jsonStore.updateHiddens({
    parentKey: [''],

    keyException: [
      'words',
      'regulars',
    ]
  });

  // изначальная установка даннных
  jsonStore.setReference(store.dictionary);
}

const getUseStoreCbNickname = (useStore: TJsonUseStore['useStore']) => {
  useDictionaryStoreNickname = useStore;

  const jsonStore = useStore();

  // устанавливаем расширения
  jsonStore.addExtensionComponent('dictionary-ban-word-nickname', InputTagDictionaryBanWordNickname);

  jsonStore.updateExtensions({
    key: {
      'words': ['dictionary-ban-word-nickname'],
    },
  });

  // устанавливаем классы
  jsonStore.updateClassNames({
    viewer: {
      key: {
        'words': "--hidden-row",
      }
    }
  });

  jsonStore.updateHiddens({
    parentKey: [''],

    keyException: [
      'words',
    ]
  });

  // изначальная установка даннных
  jsonStore.setReference(storeNickname.dictionary);
}

const isStoreReady = ref(false);
const isStoreNicknameReady = ref(false);

onMounted(async () => {
  const route = useRoute();
  const deal = parseInt((route?.params?.id ?? '') + '', 10);
  const idDictionaryNickname = 2;

  if (Number.isInteger(deal)) {
    dealId.value = deal;
    await store.getDataById(deal); 
    isStoreReady.value = true; 
    await storeNickname.getDataById(idDictionaryNickname); 
    isStoreNicknameReady.value = true; 
  }

  moveRegularsContainer();
});

const moveRegularsContainer = () => {
  nextTick(() => {
    const regularsContainer = document.querySelector('.--requlars-container');
    const regulars = document.querySelector('.--regulars-inside');

    if (regularsContainer && regulars) {
      regulars.appendChild(regularsContainer);
    }
  });
};

watch(
  [() => store, () => storeNickname],
  async () => {
    if (isStoreReady.value && isStoreNicknameReady.value) {
      moveRegularsContainer();
    }
  },
  { deep: true, immediate: true }
);

onUnmounted(() => {
    store.$reset();
    storeNickname.$reset();
    useDictionaryStore?.().$reset();
    useDictionaryStoreNickname?.().$reset();
});

const saveDictionaryHandler = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  try {
    const dictionaryStore = useDictionaryStore();
    const referenceData = JSON.stringify(dictionaryStore.reference, null, 2);
    const parsedData = JSON.parse(referenceData);
    const dictionaryStoreNickname = useDictionaryStoreNickname();
    const referenceDataNickname = JSON.stringify(dictionaryStoreNickname.reference, null, 2);
    const parsedDataNickname = JSON.parse(referenceDataNickname);

    // Разделяем данные и обновляем два стора
    if (store.isUpdated) {
      store.updateData(parsedData);
    }
    if (storeNickname.isUpdated) {
      storeNickname.updateData(parsedDataNickname);
    }

  } catch (error) {
    console.error('Ошибка при сохранении изменений:', error);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Изменить словарь")
      ])),
      _: 1
    }),
    (!_unref(isUserCanViewDictionary)())
      ? (_openBlock(), _createBlock(Card, {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(CardTitle, null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(isUserCanEditDictionary)())
      ? (_openBlock(), _createBlock(Card, {
          key: 1,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            (_unref(store).$loading || _unref(storeNickname).$loading)
              ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
              : (_unref(store).$error && _unref(storeNickname).$error)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Произошла ошибка получения данных.", -1)),
                    _cache[6] || (_cache[6] = _createElementVNode("p", null, [
                      _createElementVNode("strong", null, "Попробуйте перезагрузить страницу"),
                      _createTextVNode(", если это не поможет "),
                      _createElementVNode("strong", null, "обратитесь в поддержку"),
                      _createTextVNode(". ")
                    ], -1)),
                    _cache[7] || (_cache[7] = _createElementVNode("p", null, [
                      _createElementVNode("strong", null, "Данные об ошибке.")
                    ], -1)),
                    _createElementVNode("p", null, [
                      _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Обозначение ошибки: ", -1)),
                      _createTextVNode(_toDisplayString(_unref(store).$error.error || _unref(storeNickname).$error.error), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[3] || (_cache[3] = _createElementVNode("strong", null, "ID запроса: ", -1)),
                      _createTextVNode(_toDisplayString(_unref(store).$error.requestId || _unref(storeNickname).$error.error), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Код ответа: ", -1)),
                      _createTextVNode(_toDisplayString((_unref(store).$error.code || _unref(storeNickname).$error.code) ?? ''), 1)
                    ]),
                    _cache[8] || (_cache[8] = _createElementVNode("p", null, " ", -1))
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createElementVNode("div", _hoisted_1, [
                      _cache[10] || (_cache[10] = _createElementVNode("p", null, "Название словаря:", -1)),
                      _createVNode(CardTitle, null, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("Проверка спама в сообщениях")
                        ])),
                        _: 1
                      })
                    ]),
                    _createVNode(JsonViewer, { getUseStoreCb: getUseStoreCb }),
                    _createVNode(JsonViewer, { getUseStoreCb: getUseStoreCbNickname }),
                    (_unref(store).isUpdated || _unref(storeNickname).isUpdated)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-inverse-primary btn-fw",
                          onClick: saveDictionaryHandler
                        }, "Сохранить изменения"))
                      : _createCommentVNode("", true)
                  ], 64))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})