<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue';
import { TJsonExtensionProps } from 'litsystems-vue-json-editor-dev/src';
import { useDictionaryEditItemStore } from '../store';
import { NameKeys, nameKeysDictionary } from '../types';
import Row from "@/components/kit/Row.vue";

const props = defineProps<TJsonExtensionProps>();
const editStore = useDictionaryEditItemStore();

const inputValue = ref("");
const tagAlreadyDictionary = ref("");

const filteredTags = computed(() => {
  const normalizeText = (text: string) => text.trim().replace(/\s+/g, ' ').toLowerCase();

  const searchWords = inputValue.value
    .split(',')
    .map(word => normalizeText(word))
    .filter(word => word);

  if (!searchWords.length) {
    return [];
  }

  return editStore.dictionary.words.filter((tag: string) =>
    searchWords.some(searchWord => normalizeText(tag).includes(searchWord))
  );
});

const labelName = computed(() => {
  return nameKeysDictionary[props.propKey as keyof NameKeys] || props.propKey;
});

const updateStore = () => {
  const jsonStore = props.useStore();
  jsonStore.setValueByKeys(
    [...props.parentKeys, props.propKey].slice(1),
    [...editStore.dictionary.words]
  );
};

const addTags = (tags: string) => {
  const normalizeText = (text: string) => text.trim().replace(/\s+/g, ' ').toLowerCase();

  const newTags = tags
    .split(',')
    .map(tag => normalizeText(tag))
    .filter((tag, index, self) =>
      tag && self.indexOf(tag) === index
    );

  const existingTags = newTags.filter(tag =>
    editStore.dictionary.words.some((existingTag: string) => normalizeText(existingTag) === tag)
  );

  const uniqueNewTags = newTags.filter(tag => !existingTags.includes(tag));

  if (uniqueNewTags.length) {
    editStore.isUpdated = true;
    editStore.dictionary.words.push(...uniqueNewTags);
    updateStore();
  }

  if (existingTags.length) {
    tagAlreadyDictionary.value = `Данные теги уже есть в словаре`;
  } else {
    tagAlreadyDictionary.value = "";
  }

  inputValue.value = existingTags.join(', ');
};

const handleInput = (e: KeyboardEvent) => {
  const input = e.target as HTMLTextAreaElement;

  if (e.key === 'Backspace' && !input.value.trim() && editStore.dictionary.words.length) {
    editStore.isUpdated = true;
    editStore.dictionary.words.pop();
    updateStore();
    return;
  }

  if (e.key === 'Enter') {
    e.preventDefault();
    e.stopPropagation();
    addTags(input.value);
  }
};

const addTag = () => {
  if (inputValue.value.trim()) {
    addTags(inputValue.value);
  }
};

const removeTag = (index: number) => {
  editStore.isUpdated = true;
  editStore.dictionary.words.splice(index, 1);
  updateStore();
};

const removeFilteredTag = (tag: string) => {
  const index = editStore.dictionary.words.indexOf(tag);
  if (index !== -1) {
    removeTag(index);
  }
};

const sentence = ref("");
const highlightedSentence = ref<string>("");
const showCheckField = ref(false);
const isChecked = ref(false);

const checkSentence = () => {
  let sentenceText = sentence.value.trim().toLowerCase().replace(/\s+/g, ' ');

  if (!sentenceText) {
    highlightedSentence.value = "";
    isChecked.value = false;
    return;
  }

  const uniqueWords = Array.from(editStore.dictionary.words);

  let highlightedText = sentenceText;

  uniqueWords.forEach((word) => {
    let startIndex = 0;

    while ((startIndex = highlightedText.toLowerCase().indexOf(word, startIndex)) !== -1) {
      const beforeWord = highlightedText.slice(0, startIndex);
      const wordToHighlight = highlightedText.slice(startIndex, startIndex + word.length);
      const afterWord = highlightedText.slice(startIndex + word.length);

      highlightedText = `${beforeWord}<span class='highlight'>${wordToHighlight}</span>${afterWord}`;

      startIndex += word.length + `<span class='highlight'></span>`.length;
    }
  });

  highlightedSentence.value = highlightedText;
  isChecked.value = true;
};

const toggleCheckField = () => {
  showCheckField.value = !showCheckField.value;
  isChecked.value = false;
};
</script>


<template>
  <Row class="tab-transparent-content">
    <div class="col-lg-6 height-textarea --regulars-inside">
      <label class="key-label margin-both">
        Добавление и поиск запрещенных слов
      </label>
      <textarea class="ban-word__form"
        placeholder="Добавьте или найдите теги (через запятую, нажмите Enter для добавления, либо на кнопку ниже)"
        v-model="inputValue" @keydown="handleInput"></textarea>
      <div v-if="tagAlreadyDictionary" class="error-message">
        {{ tagAlreadyDictionary }}
      </div>
      <button class="btn btn-success margin-top" @click="addTag" :disabled="!inputValue.trim()">
        Добавить тег
      </button>
      <div>
        <label v-if="inputValue" class="key-label margin-top">Встречающиеся слова в словаре</label>
        <div v-if="inputValue">
          <div v-if="filteredTags.length" class="filtered-tags">
            <div v-for="(tag, index) in filteredTags" :key="index" class="filtered-tag">
              <span>{{ tag }}</span>
              <a href="#" @click.prevent="removeFilteredTag(tag)">×</a>
            </div>
          </div>
          <div v-else class="no-matches">
            Совпадений нет
          </div>
        </div>
      </div>

      <div v-if="showCheckField" class="check-sentence">
        <textarea v-model="sentence" class="ban-word__form" placeholder="Введите предложение для проверки"></textarea>
        <div v-if="isChecked" v-html="highlightedSentence" class="highlighted-sentence"></div>
        <button class="btn btn-primary margin-sides margin-top" @click="checkSentence">Проверить</button>
        <button class="btn btn-primary margin-top" @click="toggleCheckField">Скрыть проверку</button>
      </div>

      <button v-else class="btn btn-primary margin-top" @click="toggleCheckField">
        Проверить сообщение
      </button>
    </div>

    <div class="col-lg-6">
      <label class="key-label margin-both">
        {{ labelName }}
      </label>

      <div class="tagsinput">
        <div class="tags-container">
          <div v-for="(tag, index) in editStore.dictionary.words" :key="index" class="tag">
            <span>{{ tag }}</span>
            <a href="#" @click.prevent="removeTag(index)">×</a>
          </div>
        </div>
      </div>
    </div>
  </Row>
</template>


<style>
.height-textarea textarea {
  min-height: 191px;
}

.ban-word__form {
  width: 100%;
  padding: 8px;
  font-size: 0.8125rem;
  border-radius: 4px;
  border: 1px solid rgba(151, 151, 151, 0.3);
}

.tags-container {
  max-height: 500px;
  overflow-y: auto;
  padding: 8px;
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 4px;
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

div.tagsinput {
  padding: 15px;
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.tag {
  display: inline-flex;
  align-items: center;
  color: #000;
  border: 2px solid #0062ff;
  padding: 6px 14px;
  border-radius: 5px;
  font-size: 0.8125rem;
}

.tag a {
  color: #0062ff;
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

.button-container {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.button-container .btn {
  flex: 1;
}

.check-sentence {
  margin-top: 16px;
}

.margin-sides {
  margin: 0 5px;
}

.highlighted-sentence {
  margin-top: 8px;
  padding: 8px;
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 4px;
  background: #f9f9f9;
  white-space: pre-wrap;
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}

.filtered-tags {
  margin-top: 16px;
  padding: 15px;
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 4px;
}

.filtered-tag {
  display: inline-flex;
  align-items: center;
  background: #dbdbdb;
  color: #333;
  padding: 6px 14px;
  border-radius: 3px;
  font-size: 0.8125rem;
  margin: 4px;
}

.filtered-tag a {
  color: #333;
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

.no-matches {
  margin-top: 16px;
  padding: 15px;
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 4px;
  font-size: 0.8125rem;
  background-color: #f9f9f9;
  color: #999;
  text-align: center;
}
</style>
