import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex gap-2 align-items-center"
}
const _hoisted_2 = { class: "row container-row-table" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = {
  key: 1,
  class: "d-flex gap-2 align-items-center"
}

import { TCategoryData } from '../store';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputCategoryDesc',
  props: {
    rowData: {}
  },
  setup(__props: any) {

const props = __props;
const { rowData } = props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", null, [
    (!_unref(rowData).isEditing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", {
              textContent: _toDisplayString(_unref(rowData).description)
            }, null, 8, _hoisted_3),
            _createElementVNode("span", {
              class: "gray",
              textContent: _toDisplayString('Черновики: ' + _unref(rowData).draftCount)
            }, null, 8, _hoisted_4),
            _createElementVNode("span", {
              class: "gray",
              textContent: _toDisplayString('Экспорт: ' + _unref(rowData).publishedCount)
            }, null, 8, _hoisted_5)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _withDirectives(_createElementVNode("input", {
            class: "category-input-table",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(rowData).description) = $event))
          }, null, 512), [
            [_vModelText, _unref(rowData).description]
          ])
        ]))
  ]))
}
}

})