import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"


import { ITableCellProps } from '@/components/Table/types';
// store
import { useAsyncFaqListStore } from '../store'; 



export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryTd',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const tableStore = useAsyncFaqListStore(); 

const props = __props;
var categories = props.value as {id: number; name: string}[];

// если у таблицы применён фильтр по категории
var filterByCategory = parseInt(tableStore.filters[3]?.value?.[0], 10);
if (filterByCategory && Array.isArray(categories)) {
    categories = categories.sort((category)=>{ return category.id === filterByCategory ? -1 : 1 });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", {
    class: _normalizeClass(["table__td-faq-category", {'table__td-faq-category--filtered': _unref(filterByCategory)}])
  }, [
    (Array.isArray(_unref(categories)))
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(categories), (category, k) => {
          return (_openBlock(), _createElementBlock("span", {
            key: k * 1000 + category.id,
            class: _normalizeClass({'table__td-faq-category--active': _unref(filterByCategory) && _unref(filterByCategory) === category.id })
          }, _toDisplayString(category.name), 3))
        }), 128))
      : _createCommentVNode("", true)
  ], 2))
}
}

})