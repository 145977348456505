<script setup lang="ts">
defineProps({
    isVisible: {
        type: Boolean,
        required: true,
    },
    message: {
        type: String,
        required: true,
    },
    showSaveButton: {  
        type: Boolean,
        default: false,
    },
    showCloseButton: { 
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['save', 'close']);

const onSave = () => emit('save');
const onClose = () => emit('close');
</script>

<template>
    <div v-if="isVisible" class="modal-overlay-answer">
        <div class="modal-content-answer">
            <h3>Внимание!</h3>
            <p v-html="message"></p>
            <div class="modal-buttons">
                <button 
                    v-if="showSaveButton"
                    class="btn btn-success margin-top-faq" 
                    @click="onSave"
                >
                    Сохранить
                </button>
                <button 
                    v-if="showCloseButton"
                    class="btn btn-danger margin-top-faq" 
                    @click="onClose"
                >
                    Закрыть
                </button>
            </div>
        </div>
    </div>
</template>

<style>
.modal-overlay-answer h3, .modal-overlay-answer p {
    color: black;
    max-width: 100%; 
    white-space: pre-wrap;
}
</style>