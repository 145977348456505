import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-6 height-textarea margin-both" }
const _hoisted_3 = {
  key: 0,
  class: "error-message"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "key-label margin-top"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "filtered-tags"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 1,
  class: "no-matches"
}
const _hoisted_10 = {
  key: 1,
  class: "check-sentence"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "col-lg-6" }
const _hoisted_13 = { class: "key-label margin-top" }
const _hoisted_14 = { class: "tagsinput margin-both" }
const _hoisted_15 = { class: "tags-container" }
const _hoisted_16 = ["onClick"]

import { ref, computed } from 'vue';
import { TJsonExtensionProps } from 'litsystems-vue-json-editor-dev/src';
import { useDictionaryEditNicknameStore } from '../store';
import { NameKeys, nameKeysDictionary } from '../types';
import CardTitle from '@/components/kit/CardTitle.vue';
import Row from "@/components/kit/Row.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputTagDictionaryBanWordNickname',
  props: {
    type: {},
    propKey: {},
    keyForVue: {},
    parentKeys: {},
    humanKey: {},
    childs: {},
    value: { type: [String, Number, Boolean, null] },
    className: {},
    extension: {},
    hidden: { type: Boolean },
    useStore: {}
  },
  setup(__props: any) {

const props = __props;
const editStore = useDictionaryEditNicknameStore();

const inputValue = ref("");
const tagAlreadyDictionary = ref("");

const filteredTags = computed(() => {
  const normalizeText = (text: string) => text.trim().replace(/\s+/g, ' ').toLowerCase();

  const searchWords = inputValue.value
    .split(',')
    .map(word => normalizeText(word))
    .filter(word => word);

  if (!searchWords.length) {
    return [];
  }

  return editStore.dictionary.words.filter((tag: string) =>
    searchWords.some(searchWord => normalizeText(tag).includes(searchWord))
  );
});

const labelName = computed(() => {
  return nameKeysDictionary[props.propKey as keyof NameKeys] || props.propKey;
});

const updateStore = () => {
  const jsonStore = props.useStore();
  jsonStore.setValueByKeys(
    [...props.parentKeys, props.propKey].slice(1),
    [...editStore.dictionary.words]
  );
};

const addTags = (tags: string) => {
  const normalizeText = (text: string) => text.trim().replace(/\s+/g, ' ').toLowerCase();

  const newTags = tags
    .split(',')
    .map(tag => normalizeText(tag))
    .filter((tag, index, self) =>
      tag && self.indexOf(tag) === index
    );

  const existingTags = newTags.filter(tag =>
    editStore.dictionary.words.some((existingTag: string) => normalizeText(existingTag) === tag)
  );

  const uniqueNewTags = newTags.filter(tag => !existingTags.includes(tag));

  if (uniqueNewTags.length) {
    editStore.isUpdated = true;
    editStore.dictionary.words.push(...uniqueNewTags);
    updateStore();
  }

  if (existingTags.length) {
    tagAlreadyDictionary.value = `Данные теги уже есть в словаре`;
  } else {
    tagAlreadyDictionary.value = "";
  }

  inputValue.value = existingTags.join(', ');
};

const handleInput = (e: KeyboardEvent) => {
  const input = e.target as HTMLTextAreaElement;

  if (e.key === 'Backspace' && !input.value.trim() && editStore.dictionary.words.length) {
    editStore.isUpdated = true;
    editStore.dictionary.words.pop();
    updateStore();
    return;
  }

  if (e.key === 'Enter') {
    e.preventDefault();
    e.stopPropagation();
    addTags(input.value);
  }
};

const addTag = () => {
  if (inputValue.value.trim()) {
    addTags(inputValue.value);
  }
};

const removeTag = (index: number) => {
  editStore.isUpdated = true;
  editStore.dictionary.words.splice(index, 1);
  updateStore();
};

const removeFilteredTag = (tag: string) => {
  const index = editStore.dictionary.words.indexOf(tag);
  if (index !== -1) {
    removeTag(index);
  }
};

const sentence = ref("");
const highlightedSentence = ref<string>("");
const showCheckField = ref(false);
const isChecked = ref(false);

const checkSentence = () => {
  let sentenceText = sentence.value.trim().toLowerCase().replace(/\s+/g, ' ');

  if (!sentenceText) {
    highlightedSentence.value = "";
    isChecked.value = false;
    return;
  }

  const uniqueWords = Array.from(editStore.dictionary.words);

  let highlightedText = sentenceText;

  uniqueWords.forEach((word) => {
    let startIndex = 0;

    while ((startIndex = highlightedText.toLowerCase().indexOf(word, startIndex)) !== -1) {
      const beforeWord = highlightedText.slice(0, startIndex);
      const wordToHighlight = highlightedText.slice(startIndex, startIndex + word.length);
      const afterWord = highlightedText.slice(startIndex + word.length);

      highlightedText = `${beforeWord}<span class='highlight'>${wordToHighlight}</span>${afterWord}`;

      startIndex += word.length + `<span class='highlight'></span>`.length;
    }
  });

  highlightedSentence.value = highlightedText;
  isChecked.value = true;
};

const toggleCheckField = () => {
  showCheckField.value = !showCheckField.value;
  isChecked.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Row, { class: "tab-content tab-transparent-content" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("p", null, "Название словаря:", -1)),
        _createVNode(CardTitle, null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Проверка спама в имени")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { class: "key-label margin-both" }, " Добавление и поиск запрещенных слов в никнейме ", -1)),
        _withDirectives(_createElementVNode("textarea", {
          class: "ban-word__form",
          placeholder: "Добавьте или найдите теги (через запятую, нажмите Enter для добавления, либо на кнопку ниже)",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
          onKeydown: handleInput
        }, null, 544), [
          [_vModelText, inputValue.value]
        ]),
        (tagAlreadyDictionary.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(tagAlreadyDictionary.value), 1))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "btn btn-success margin-top",
          onClick: addTag,
          disabled: !inputValue.value.trim()
        }, " Добавить тег ", 8, _hoisted_4),
        _createElementVNode("div", null, [
          (inputValue.value)
            ? (_openBlock(), _createElementBlock("label", _hoisted_5, "Встречающиеся слова в словаре"))
            : _createCommentVNode("", true),
          (inputValue.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (filteredTags.value.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredTags.value, (tag, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "filtered-tag"
                        }, [
                          _createElementVNode("span", null, _toDisplayString(tag), 1),
                          _createElementVNode("a", {
                            href: "#",
                            onClick: _withModifiers(($event: any) => (removeFilteredTag(tag)), ["prevent"])
                          }, "×", 8, _hoisted_8)
                        ]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, " Совпадений нет "))
              ]))
            : _createCommentVNode("", true)
        ]),
        (showCheckField.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((sentence).value = $event)),
                class: "ban-word__form",
                placeholder: "Введите предложение для проверки"
              }, null, 512), [
                [_vModelText, sentence.value]
              ]),
              (isChecked.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    innerHTML: highlightedSentence.value,
                    class: "highlighted-sentence"
                  }, null, 8, _hoisted_11))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "btn btn-primary margin-sides margin-top",
                onClick: checkSentence
              }, "Проверить"),
              _createElementVNode("button", {
                class: "btn btn-primary margin-top",
                onClick: toggleCheckField
              }, "Скрыть проверку")
            ]))
          : (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "btn btn-primary margin-top margin",
              onClick: toggleCheckField
            }, " Проверить сообщение "))
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("label", _hoisted_13, _toDisplayString(labelName.value), 1),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(editStore).dictionary.words, (tag, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "tag"
              }, [
                _createElementVNode("span", null, _toDisplayString(tag), 1),
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(($event: any) => (removeTag(index)), ["prevent"])
                }, "×", 8, _hoisted_16)
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})