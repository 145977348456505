<script setup lang="ts">
import { inject } from 'vue';

interface ITableStore {
  loading: boolean;
  columns: any[]; 
}

const tableStore = inject<ITableStore>('tableStore');

if (!tableStore) {
  throw new Error('tablestore не передан');
}
</script>

<template>
  <tbody>
    <tr>
      <td
        v-if="!tableStore.loading"
        :colspan="tableStore.columns.length"
      >
        Ничего не найдено
      </td>
    </tr>
  </tbody>
</template>