import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "edit-table-cell" }
const _hoisted_2 = { class: "edit-table-container" }
const _hoisted_3 = {
  key: 1,
  class: "d-flex gap-2 align-items-center"
}
const _hoisted_4 = ["disabled"]

import { computed, ref } from 'vue';
import { useAsyncPlaceholderAllListStore } from '../store/FaqPlaceholderStore';
import { deletePlaceholder, getIdQuestionPlaceholder, updatePlaceholder } from '../bff-service';
import { ITableCellProps } from '@/components/Table';
import ModalWarning from './ModalWarning.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlaceholderEditCell',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const tableStore = useAsyncPlaceholderAllListStore();
const props = __props;
const { rowData } = props;
const key = ref(props.rowData.key);
const value = ref(rowData.value);

const isApplyDisabled = computed(() => !rowData.value?.trim()); // Проверка на пустое значение
const isPlaceholderWarningVisible = ref(false);
const placeholderWarningMessage = ref('');
const faqIds = ref<number[]>([]);

const applyEditing = async (row: any) => {
  try {
    await updatePlaceholder(row.id, { key: row.key, value: row.value, meta: '' });
    await tableStore.getData();
    row.isEditing = false; 
  } catch (error) {
    console.error('Ошибка при обновлении плейсхолдера', error);
  }
};

const cancelEditing = (row: any) => {
  row.isEditing = false;
  row.key = key.value;
  row.value = value.value;
};

const deletePlaceholderById = async () => {
  const isApproved = window.confirm(`Удалить плейсхолдер "${props.rowData.key}"?`);
  if (!isApproved) return;

  const response = await getIdQuestionPlaceholder(props.rowData.key);
  if (response?.data && response.data.length > 0) {
    faqIds.value = response.data;
    placeholderWarningMessage.value = `Невозможно удалить, данный плейсхолдер используется в следующих вопросах: ${response.data.join(', ')}`;
    isPlaceholderWarningVisible.value = true;
    return;
  }

  await deletePlaceholder(props.rowData.id);
  await tableStore.getData();
};

const closeWarningModal = () => {
  isPlaceholderWarningVisible.value = false;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_unref(rowData).isEditing)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-outline-secondary btn-icon",
            title: "Редактировать",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(rowData).isEditing = !_unref(rowData).isEditing))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("i", { class: "mdi mdi-pencil" }, null, -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "btn btn-outline-success btn-icon",
              title: "Применить редактирование",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (applyEditing(_unref(rowData)))),
              disabled: isApplyDisabled.value
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("i", { class: "mdi mdi-check" }, null, -1)
            ]), 8, _hoisted_4),
            _createElementVNode("button", {
              class: "btn btn-outline-primary btn-icon",
              title: "Отменить редактирование",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (cancelEditing(_unref(rowData))))
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("i", { class: "mdi mdi-close" }, null, -1)
            ])),
            _createElementVNode("button", {
              class: "btn btn-outline-danger btn-icon",
              title: "Удалить",
              onClick: deletePlaceholderById
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("i", { class: "fa fa-trash-o" }, null, -1)
            ]))
          ]))
    ]),
    _createVNode(ModalWarning, {
      isVisible: isPlaceholderWarningVisible.value,
      message: placeholderWarningMessage.value,
      onClose: closeWarningModal,
      showCloseButton: true
    }, null, 8, ["isVisible", "message"])
  ]))
}
}

})