import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-lg-6"
}
const _hoisted_2 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = ["disabled"]

import { provide, ref, watch } from 'vue';
import { defineComponent } from 'vue';
// import { createModerator, getList } from './bff-service'; // Импортируем функцию из BFF-сервиса.
// components
import {
    Card,
    CardTitle,
    MinWidthAndScroll,
    Table,
    TablePagination
} from '@/components/kit';
import { EmptyTr, Row as TableRow } from './components';
import AllPlaceholdersFilters from './components/AllPlaceholdersFilters.vue';
// store
import { isUserCanEditPlaceholder, isUserCanViewPlaceholder } from '@/store';
import { createPlaceholder } from './bff-service';
import { useAsyncPlaceholderAllListStore } from './store/FaqPlaceholderStore';
import InputPlaceHolderValue from './components/InputPlaceHolderValue.vue';
import PlaceholderEditCell from './components/PlaceholderEditCell.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FaqPlaceholder',
  setup(__props) {

const tableStore = useAsyncPlaceholderAllListStore();

tableStore.columns = [
  { key: 'id', title: '#' },
  { key: 'key', title: 'Ключ', searchable: true, filterable: true },
  { key: 'value', title: 'Значение', searchable: true, filterable: true, component: InputPlaceHolderValue },
];

if(isUserCanEditPlaceholder()){
  tableStore.columns.push({ key: 'edit', title: 'Редактировать', component: PlaceholderEditCell });
}

provide('tableStore', tableStore);
tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
  tableStore.row = {};
}

tableStore.row.component = TableRow;

if (isUserCanViewPlaceholder()) {
    tableStore.getData();
}

const isCreatePlaceholderVisible = ref(false);

// данные в инпутах при добавление плейсхолдера
const newPlaceholder = ref({
    key: '',
    value: '',
    meta: '',
    status: true
});

const errorMessages = ref({
    key: ''
});

const placeholders = ref<string[]>([]);

watch(
  () => tableStore.data, // При загрузке data не успевает подгружаться, следим за обновлением
  (newData) => {
    placeholders.value = newData.map((placeholder) => placeholder.key);
  },
  { immediate: true } 
);

// Следим за изменением ключа и сбрасываем ошибку
watch(
  () => newPlaceholder.value.key,
  () => {
    errorMessages.value.key = ''; // Сбрасываем ошибку при изменении ключа
  }
);

const createNewPlaceholder = async () => {
  const response = await createPlaceholder(newPlaceholder.value);
  if (response && response.status === false) {
    errorMessages.value.key = 'Плейсхолдер с таким ключом уже существует';
    return; // Не обновляем таблицу
  }

  if (response && response.status === true) {
    newPlaceholder.value = {
      key: '',
      value: '',
      meta: '',
      status: true
    };
    isCreatePlaceholderVisible.value = false;
    tableStore.getData(); // Обновляем таблицу
  }
};

defineComponent({ name: 'AllPlaceholderTable' });

return (_ctx: any,_cache: any) => {
  const _component_Row = _resolveComponent("Row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(isUserCanViewPlaceholder)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(isUserCanViewPlaceholder)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 1,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Плейсхолдеры FAQ")
              ])),
              _: 1
            }),
            (_unref(isUserCanEditPlaceholder)())
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("p", null, [
                    _createElementVNode("button", {
                      class: "btn btn-inverse-primary btn-fw",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (isCreatePlaceholderVisible.value = !isCreatePlaceholderVisible.value))
                    }, _toDisplayString(isCreatePlaceholderVisible.value ? 'Закрыть' : 'Добавить плейсхолдер'), 1)
                  ]),
                  (isCreatePlaceholderVisible.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "telegram_user_id" }, "Ключ", -1)),
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            id: "telegram_user_id",
                            class: "form-control",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newPlaceholder.value.key) = $event)),
                            placeholder: "Введите ключ плейсхолдера"
                          }, null, 512), [
                            [
                              _vModelText,
                              newPlaceholder.value.key,
                              void 0,
                              { trim: true }
                            ]
                          ]),
                          (errorMessages.value.key)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(errorMessages.value.key), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _cache[6] || (_cache[6] = _createElementVNode("label", { for: "description" }, "Значение", -1)),
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            id: "description",
                            class: "form-control",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((newPlaceholder.value.value) = $event)),
                            placeholder: "Введите значение плейсхолдера"
                          }, null, 512), [
                            [
                              _vModelText,
                              newPlaceholder.value.value,
                              void 0,
                              { trim: true }
                            ]
                          ])
                        ]),
                        _createElementVNode("button", {
                          onClick: createNewPlaceholder,
                          class: "btn btn-success",
                          disabled: !newPlaceholder.value.key.trim() || !newPlaceholder.value.value.trim()
                        }, " Создать ", 8, _hoisted_6)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Row, null, {
              default: _withCtx(() => [
                _createVNode(_unref(MinWidthAndScroll), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Table), { "use-store": _unref(useAsyncPlaceholderAllListStore) }, {
                      default: _withCtx(() => [
                        _createVNode(AllPlaceholdersFilters)
                      ]),
                      _: 1
                    }, 8, ["use-store"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(TablePagination), {
              class: "tab-content tab-transparent-content",
              "use-store": _unref(useAsyncPlaceholderAllListStore)
            }, null, 8, ["use-store"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})