import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withModifiers as _withModifiers, resolveComponent as _resolveComponent } from "vue"
import _imports_0 from '../assets/img/json.webp'
import _imports_1 from '../assets/img/csv.svg'


const _hoisted_1 = { class: "col-lg-6 button-question-container" }
const _hoisted_2 = {
  key: 0,
  href: "/faq-create/?isCreating=true"
}
const _hoisted_3 = {
  key: 0,
  class: "col-lg-4 question-selected-inport"
}
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 1,
  class: "loading-content"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "file-preview"
}
const _hoisted_8 = {
  key: 0,
  class: "drag-and-drop_image",
  src: _imports_0
}
const _hoisted_9 = {
  key: 1,
  class: "drag-and-drop_image",
  src: _imports_1
}
const _hoisted_10 = {
  key: 2,
  class: "col-lg-4 button-question-container"
}
const _hoisted_11 = {
  key: 3,
  class: "col-lg-4"
}
const _hoisted_12 = { class: "text-danger" }

import { ref, defineComponent, onMounted, computed, provide } from 'vue';
import {
  Card,
  CardTitle,
  MinWidthAndScroll,
  Table,
  TablePagination,
} from '@/components/kit';
import { EmptyTr, Row as TableRow } from './components';
import { isUserCanEditFaq, isUserCanViewFaq } from '@/store';
import AllFaqQuestionsFilters from './components/AllFaqQuestionsFilters.vue';
import FaqQuestionEdit from './components/FaqQuestionEdit.vue';
import QuestionTdTable from './components/QuestionTd.vue';
import CategoryTd from './components/CategoryTd.vue';
import FaqCheckoutDelete from './components/FaqCheckoutDelete.vue'
import { useAsyncFaqListStore } from './store';
import { showNotification } from './components/notificationService';
import { getFaqCategories, uploadFile, deleteFaqQuestions, getFaqInvalidImport } from './bff-service';
import DotIndicator from './components/DotIndicator.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FaqQuestionsList',
  setup(__props) {

const tableStore = useAsyncFaqListStore();
const showImport = ref(false);
const fileInput = ref<HTMLInputElement | null>(null);
const selectedFile = ref<File | null>(null);
const componentDataLoaded = ref<boolean>(false);
const selectedCategory = ref<string>('none');
const categoryList = ref([{ value: 'none', label: 'Выберите категорию' }]);
const isLoading = ref(false);
const isAllSelected = ref(false);
const fileName = ref('');
const fileType = ref<'csv' | 'json'>('csv');
let errorsCount = ref(0);
let requestId = '';

const fetchData = async () => {
  try {
    const resCategories = await getFaqCategories();
    categoryList.value = [
      { value: 'none', label: 'Выберите категорию' },
      ...resCategories.list.map(i => ({
        value: i.id + '',
        label: i.name
      }))
    ];
    componentDataLoaded.value = true;
  } catch (error) {
    console.error(error);
    alert('Произошла ошибка, попробуйте перезагрузить страницу.');
  }
};

onMounted(() => {
  fetchData();
});

tableStore.columns = [
  { key: 'faq_question_viewed', title: '', searchable: false, filterable: false, component: DotIndicator},
  { key: 'checkDelete', title: "Выбрать", searchable: false, filterable: false, component: FaqCheckoutDelete},
  { key: 'id', title: 'ID', searchable: true, filterable: true },
  { key: 'category', title: 'Категория', searchable: true, filterable: true, component: CategoryTd },
  { key: 'question_text', title: 'Вопрос', searchable: true, filterable: true, component: QuestionTdTable },
];

if (isUserCanEditFaq()) {
  tableStore.columns.push({
    key: 'confirmed',
    title: 'Редактирование',
    searchable: true,
    filterable: true,
    component: FaqQuestionEdit,
  });
}

provide('tableStore', tableStore);
tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
  tableStore.row = {};
}

tableStore.row.component = TableRow;

const handleFileUpload = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  if (files && files.length > 0) {
    selectedFile.value = files[0];
    fileName.value = selectedFile.value.name;
    const ext = selectedFile.value.name.split('.').pop()?.toLowerCase() || '';
    fileType.value = ext === 'csv' ? 'csv' : 'json';
    showNotification('success', `Файл '${selectedFile.value.name}' загружен`, 3000);
  }
};

const onDrop = (event: DragEvent) => {
  event.preventDefault();
  if (!event.dataTransfer || event.dataTransfer.files.length === 0) return;
  selectedFile.value = event.dataTransfer.files[0];
  showNotification('success', `Файл '${selectedFile.value.name}' загружен`, 3000);
};

const resetFileInput = () => {
  if (fileInput.value) {
    fileInput.value.value = '';
  }
};

const removeFile = () => {
  selectedFile.value = null;
  resetFileInput();
  showNotification('success', 'Файл удалён из импорта', 3000);
};

const onDragOver = (event: DragEvent) => {
  event.preventDefault();
};

const createOrUpdateFaqQuestions = async () => {
  if (!selectedFile.value) {
    showNotification('error', 'Нет файла для импорта', 3000);
    return;
  }

  isLoading.value = true; 

  try {
    const response = await uploadFile(selectedFile.value, selectedCategory.value, selectedFile.value.type);
    if (response.status) {
      const { created, updated, errorCount, req_id } = response; 
      errorsCount = errorCount;
      requestId = req_id;
      if (created > 0 || updated > 0) {
        showNotification('success', `Файл успешно импортирован. Создано: ${created}, Обновлено: ${updated}. \nОшибок при импорте: ${errorCount}`, 6000);
      } else {
        showNotification('error', 'Файл импортирован, но изменений не произошло.', 5000);
      }
      await tableStore.getData(); 
      resetFileInput();
      selectedFile.value = null;
    } else {
      showNotification('error', 'Ошибка при импорте файла. Проверьте файл и попробуйте снова.', 5000);
    }
  } catch (error) {
    showNotification('error', `Ошибка при загрузке файла, ${error}. Попробуйте еще раз!`, 3000);
  } finally {
    isLoading.value = false; 
  }
};

const transformDataToCSV = (data: any[]) => {
  const csvRows: string[] = [];

  data.forEach(item => {
    const id = item.id || '';
    const question = item.question_text || '';
    const answer = item.answer || '';

    const alternativeQuestions = item.alternative_question || [];
    const question2 = alternativeQuestions[0]?.variant_text || '';
    const question3 = alternativeQuestions[1]?.variant_text || '';

    const row = [id, question, question2, question3, answer].join(';');
    csvRows.push(row);
  });

  return csvRows.join('\n');
};

const transformDataToJSON = (data: any[]) => {
  return data.map(item => {
    const transformedItem: any = {
      id: item.id,
      question: item.question_text || '', 
    };

    if (item.alternative_question && item.alternative_question.length > 0) {
      item.alternative_question.forEach((altQuestion: any, index: number) => {
        transformedItem[`question${index + 2}`] = altQuestion.variant_text || '';
      });
    }
    transformedItem.answer = item.answer || '';

    return transformedItem;
  });
};

const downloadErrors = async (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
  if (!requestId) return;

  try {
    const response = await getFaqInvalidImport(requestId);
    if (response.data) {
      const dataArray = JSON.parse(response.data) as string[];
      const parsedData = dataArray.map(item => JSON.parse(item));

      let content: string, mimeType: string;

      if (fileType.value === 'csv') {
        content = transformDataToCSV(parsedData);
        mimeType = 'text/csv';
      } else {
        const transformedData = transformDataToJSON(parsedData);
        content = JSON.stringify(transformedData, null, 2);
        mimeType = 'application/json';
      }

      const blob = new Blob([content], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `errors_${fileName.value}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  } catch (error) {
    console.error('Ошибка при скачивании:', error);
    showNotification('error', 'Не удалось скачать файл с ошибками', 3000);
  }
};

const toggleAllQuestions = () => {
  tableStore.data.forEach((question) => {
    question.checkDelete = false;
  });
  isAllSelected.value = !isAllSelected.value;
};

const isAnyQuestionSelected = computed(() => {
  return tableStore.data.some((question) => question.checkDelete);
});

const deleteSelectedQuestions = async () => {
  const selectedQuestions = tableStore.data.filter((question) => question.checkDelete);

  if (selectedQuestions.length === 0) return;
  const confirmDelete = confirm('Вы уверены, что хотите удалить выбранные вопросы?');
  if (!confirmDelete) return;

  try {
    const response = await deleteFaqQuestions(selectedQuestions.map((question) => question.id));
    if (response.status) {
      showNotification('success', 'Вопросы успешно удалены', 3000);
      await tableStore.getData();
    } else {
      showNotification('error', 'Ошибка при удалении вопросов', 3000);
    }
  } catch (error) {
    showNotification('error', `Ошибка при удалении вопросов: ${error}`, 3000);
  }
};

onMounted(() => {
  tableStore.clearFilters();
  tableStore.getData();
});

defineComponent({ name: 'AllFaqTable' });

return (_ctx: any,_cache: any) => {
  const _component_Row = _resolveComponent("Row")!

  return (!_unref(isUserCanViewFaq)())
    ? (_openBlock(), _createBlock(_unref(Card), {
        key: 0,
        style: {"position":"relative"}
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(CardTitle), null, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Нет прав на просмотр данных")
            ])),
            _: 1
          })
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_unref(Card), {
        key: 1,
        style: {"position":"relative"}
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(CardTitle), null, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Список FAQ")
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            (_unref(isUserCanEditFaq)())
              ? (_openBlock(), _createElementBlock("a", _hoisted_2, _cache[5] || (_cache[5] = [
                  _createElementVNode("button", { class: "btn btn-inverse-primary btn-fw" }, "Добавить вопрос", -1)
                ])))
              : _createCommentVNode("", true),
            (_unref(isUserCanEditFaq)())
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "btn btn-inverse-primary btn-fw",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (showImport.value = !showImport.value))
                }, _toDisplayString(showImport.value ? 'Закрыть импорт' : 'Импорт вопросов'), 1))
              : _createCommentVNode("", true),
            (isAnyQuestionSelected.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  class: "btn btn-danger btn-fw",
                  onClick: deleteSelectedQuestions
                }, " Удалить выбранные "))
              : _createCommentVNode("", true),
            (isAnyQuestionSelected.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 3,
                  class: "btn btn-inverse-primary btn-fw",
                  onClick: toggleAllQuestions
                }, " Отменить выбор "))
              : _createCommentVNode("", true)
          ]),
          (showImport.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedCategory).value = $event)),
                  class: "form-control"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categoryList.value, (option, k) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: k,
                      value: option.value
                    }, _toDisplayString(option.label), 9, _hoisted_4))
                  }), 128))
                ], 512), [
                  [_vModelSelect, selectedCategory.value]
                ])
              ]))
            : _createCommentVNode("", true),
          (showImport.value && selectedCategory.value !== 'none')
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "dropify-wrapper col-lg-4",
                onDrop: onDrop,
                onDragover: onDragOver,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (fileInput.value?.click()))
              }, [
                (selectedFile.value && !isLoading.value)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "remove-file-btn",
                      onClick: _withModifiers(removeFile, ["stop"])
                    }, "✖"))
                  : _createCommentVNode("", true),
                (isLoading.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[6] || (_cache[6] = [
                      _createElementVNode("div", { class: "dot-opacity-loader" }, [
                        _createElementVNode("span"),
                        _createElementVNode("span"),
                        _createElementVNode("span")
                      ], -1),
                      _createElementVNode("p", null, "Идет импорт, пожалуйста подождите!", -1)
                    ])))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      (!selectedFile.value)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _cache[7] || (_cache[7] = [
                            _createTextVNode(" Перетащите файл JSON или CSV сюда или "),
                            _createElementVNode("span", { class: "file-upload" }, "выберите файл", -1)
                          ])))
                        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            (selectedFile.value.name.endsWith('.json'))
                              ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                              : (selectedFile.value.name.endsWith('.csv'))
                                ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                                : _createCommentVNode("", true),
                            _createElementVNode("span", null, _toDisplayString(selectedFile.value.name), 1)
                          ]))
                    ], 64)),
                _createElementVNode("input", {
                  ref_key: "fileInput",
                  ref: fileInput,
                  type: "file",
                  accept: ".json,.csv",
                  onChange: handleFileUpload,
                  hidden: ""
                }, null, 544)
              ], 32))
            : _createCommentVNode("", true),
          (selectedFile.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("button", {
                  class: "btn btn-success",
                  onClick: createOrUpdateFaqQuestions
                }, " Сохранить вопросы ")
              ]))
            : _createCommentVNode("", true),
          (_unref(errorsCount) > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("p", _hoisted_12, [
                  _createTextVNode(_toDisplayString(_unref(errorsCount)) + " вопроса(ов) не удалось импортировать ", 1),
                  _createElementVNode("a", {
                    type: "button",
                    class: "text-download-error",
                    onClick: downloadErrors
                  }, "Скачать вопросы")
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_Row, null, {
            default: _withCtx(() => [
              _createVNode(_unref(MinWidthAndScroll), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(Table), { "use-store": _unref(useAsyncFaqListStore) }, {
                    default: _withCtx(() => [
                      _createVNode(AllFaqQuestionsFilters)
                    ]),
                    _: 1
                  }, 8, ["use-store"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(TablePagination), {
            class: "tab-content tab-transparent-content",
            "use-store": _unref(useAsyncFaqListStore)
          }, null, 8, ["use-store"])
        ]),
        _: 1
      }))
}
}

})