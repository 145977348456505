import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "cmi-deals-all-filters filter-faq" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["checked"]
const _hoisted_4 = { class: "faq__table-tag-filter filter_id-table-data" }
const _hoisted_5 = { class: "faq__table-tag-category custom-select-wrapper" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "faq__table-tag-filter faq__table-tag-question" }
const _hoisted_8 = { class: "faq__table-tag-confirmation custom-select-wrapper" }
const _hoisted_9 = ["value"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 0,
  class: "cmi-deals-all-filters"
}

import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
// components
import Input from '@/components/kit/Input.vue';
// store
import { useAsyncFaqListStore } from '../store';
import { getFaqCategories } from '../bff-service';


export default /*@__PURE__*/_defineComponent({
  __name: 'AllFaqQuestionsFilters',
  setup(__props) {

const tableStore = useAsyncFaqListStore();
const componentDataLoaded = ref<boolean>(false);
const selectedCategoryName = ref<string>('');
const question_text = ref<string>('');
const idQeustion = ref<string>('');
const selectedStatusQuestion = ref<string>('_all');

const categoryList = [
  {
    value: '_all',
    label: 'Все'
  }
] as {
  value: string;
  label: string;
}[];

const statusList = [
  { value: '_all', label: 'Все' },
  { value: 'false', label: 'Опубликованные' },
  { value: 'true', label: 'Черновики' }
];

const fetchData = async () => {
  try {
    const resCategories = await getFaqCategories();
    resCategories.list.forEach(i => categoryList.push({
      value: i.id + '',
      label: i.name,
    }));

    componentDataLoaded.value = true;
  } catch (error) {
    console.error(error);
    alert('Произошла ошибка, попробуйте перезагрузить страницу.');
  }
};

// При монтирование грузим выбранную категорию
onMounted(() => {
  const savedCategory = localStorage.getItem('selectedCategory');
  if (savedCategory) {
    selectedCategoryName.value = savedCategory;
  } else {
    selectedCategoryName.value = '_all'; 
  }
  fetchData();
});

watch(selectedCategoryName, (newCategory) => {
  localStorage.setItem('selectedCategory', newCategory);
});

onUnmounted(() => {
  tableStore.$reset();
});

const isAllChecked = computed(() => {
  return tableStore.data.every(question => question.checkDelete);
});

const toggleAllCheckboxes = () => {
  const newValue = !isAllChecked.value;
  tableStore.data.forEach(question => {
    question.checkDelete = newValue;
  });
};

watch([idQeustion ,selectedCategoryName, question_text, selectedStatusQuestion], ({
  0: id,
  1: category,
  2: question,
  3: status
}) => {
  // Фильтр по id вопроса
  var filterById = [];
  if(id){
    filterById.push(id);
  }
  tableStore.filterBy(2, filterById);

  // фильтр по категории
  var filterByCategory = [];
  if (!category || category !== '_all') {
    filterByCategory.push(parseInt(category, 10));
  }
  tableStore.filterBy(3, filterByCategory);

  // фильтр по вопросу
  var filterByQuestion = [];
  if (question) {
    filterByQuestion.push(question);
  }
  tableStore.filterBy(4, filterByQuestion);

  // фильтр по статусу
  let filterByStatus = [];
  if (status === 'true') {
    filterByStatus.push('true');
  } else if (status === 'false') {
    filterByStatus.push('false');
  }
  tableStore.filterBy(5, filterByStatus);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tbody", _hoisted_1, [
      (componentDataLoaded.value)
        ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
            _cache[6] || (_cache[6] = _createElementVNode("td", { colspan: "1" }, null, -1)),
            _createElementVNode("td", null, [
              _createElementVNode("input", {
                type: "checkbox",
                onChange: toggleAllCheckboxes,
                checked: isAllChecked.value
              }, null, 40, _hoisted_3)
            ]),
            _createElementVNode("td", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "mdi mdi-magnify" }, null, -1)),
              _createVNode(Input, {
                modelValue: idQeustion.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((idQeustion).value = $event)),
                type: "search"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("td", _hoisted_5, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedCategoryName).value = $event)),
                class: "form-control"
              }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(categoryList, (option, k) => {
                  return _createElementVNode("option", {
                    key: k,
                    value: option.value
                  }, _toDisplayString(option.label), 9, _hoisted_6)
                }), 64))
              ], 512), [
                [_vModelSelect, selectedCategoryName.value]
              ])
            ]),
            _createElementVNode("td", _hoisted_7, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "mdi mdi-magnify" }, null, -1)),
              _createVNode(Input, {
                modelValue: question_text.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((question_text).value = $event)),
                type: "search"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("td", _hoisted_8, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedStatusQuestion).value = $event)),
                class: "form-control"
              }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(statusList, (option, k) => {
                  return _createElementVNode("option", {
                    key: k,
                    value: option.value
                  }, _toDisplayString(option.label), 9, _hoisted_9)
                }), 64))
              ], 512), [
                [_vModelSelect, selectedStatusQuestion.value]
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("tr", _hoisted_10, _cache[7] || (_cache[7] = [
            _createElementVNode("td", {
              colspan: "4",
              class: "faq__table-tag-filter"
            }, " Загрузка фильтров... ", -1)
          ])))
    ]),
    (_unref(tableStore).loading)
      ? (_openBlock(), _createElementBlock("tbody", _hoisted_11, _cache[8] || (_cache[8] = [
          _createElementVNode("tr", null, [
            _createElementVNode("td", { colspan: "8" }, " Загрузка: ")
          ], -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}
}

})