import {
  generateAsyncDataTableStore,
  definePromiseQueue,
  mapTableFiltersToSearchParams,
} from '@/components/Table';
import { getFaqPlaceholderPublicApi, TPlaceholderSearchParams } from '../bff-service';
import { TPlaceholderGetListResDto } from '../types/dto';

export type TPlaceholderData = {
    id: number;
    key: string;
    value: string;
    meta: string;
    isEditing: boolean; 
}

const allListPromiseQueue = definePromiseQueue<TPlaceholderGetListResDto>();

export const useAsyncPlaceholderAllListStore = generateAsyncDataTableStore<TPlaceholderData>({
  initialState: {
      loading: true,
  },

  dataGetter: async (useStore) => {
    const store = useStore();

    await allListPromiseQueue.wait(allListGetter, useStore)
      .then(response=>{
        store.data = response.list.map( deal => {
          return {
            id: deal.id,
            key: deal.key,
            value: deal.value,
            meta: deal.meta,
            isEditing: false,
          };
        });
        store.paginateTotal = response.paginate.filtered;
        store.paginateFiltered = response.paginate.filtered;
      })
      .catch(e=>{
        store.data = [];
        store.paginateTotal = 0;
      })
      .finally(()=>{
        store.loading = false;
      })
  }
});


// получение списка заявок
const allListGetter = (): Promise<TPlaceholderGetListResDto> => {
  const store = useAsyncPlaceholderAllListStore();
  
  // приводим данные фильтров стора таблиц 
  // к виду данных для поиска
  const search = mapTableFiltersToSearchParams<typeof store, TPlaceholderSearchParams>(
    store, // указываем нужный стор
    // указываем данные поиска по дефолту
    {
      key: '',
      value: '',
    },
    // указываем соответствие ключей строра таблиц к ключам данных для поиска
    {
      key: 'key',
      value: 'value',
    }
  );
  return getFaqPlaceholderPublicApi(store.paginatePage, store.paginatePerPage, search);
}