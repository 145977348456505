<script setup lang="ts">
import { defineProps } from 'vue';
import { ITableCellProps } from '@/components/Table/types';
// store
import { useAsyncFaqListStore } from '../store'; 


const tableStore = useAsyncFaqListStore(); 

const props = defineProps<ITableCellProps>();
var categories = props.value as {id: number; name: string}[];

// если у таблицы применён фильтр по категории
var filterByCategory = parseInt(tableStore.filters[3]?.value?.[0], 10);
if (filterByCategory && Array.isArray(categories)) {
    categories = categories.sort((category)=>{ return category.id === filterByCategory ? -1 : 1 });
}
</script>

<template>
  <td class="table__td-faq-category" :class="{'table__td-faq-category--filtered': filterByCategory}">

    <span 
        v-if="Array.isArray(categories)" 
        v-for="(category, k) of categories" 
        :key="k * 1000 + category.id"
        :class="{'table__td-faq-category--active': filterByCategory && filterByCategory === category.id }"
    >
        {{ category.name }}
    </span>
  </td>
</template>

<style lang="scss">
.table__td-faq-category{
    &.table__td-faq-category--filtered {
        span {
            opacity: .4;
        }

        .table__td-faq-category--active {
            opacity: 1;
        }
    }

    span {
        display: block;
    }
}
</style>