<script setup lang="ts">
import { ITableCellProps } from '@/components/Table';
import { defineProps } from 'vue';

const props = defineProps<ITableCellProps>();

const handleCheckboxChange = (event: Event) => {
  props.rowData.checkDelete = (event.target as HTMLInputElement).checked;
};

</script>

<template>
  <td class="table__td-faq-category">
    <input
      type="checkbox"
      :checked="rowData.checkDelete"
      @change="handleCheckboxChange"
    />
  </td>
</template>