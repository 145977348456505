import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "edit-table-cell" }
const _hoisted_2 = { class: "edit-table-container" }
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  key: 0,
  class: "modal-overlay-answer"
}
const _hoisted_5 = { class: "modal-content-answer" }
const _hoisted_6 = { class: "textarea__count_char" }
const _hoisted_7 = { class: "char-counter" }

import { ENavy, router } from '@/router';
import { ITableCellProps } from '@/components/Table/types';
import { ref, watch } from 'vue';
import { createFaqViewed, deleteFaq, getFaqItem, getPlaceholder, updateFaq, updateFaqAnswer } from '../bff-service';
import ToastMessage from '../components/ToastMessage.vue';
import ModalWarning from './ModalWarning.vue';
import { showNotification } from './notificationService';
import { useAsyncFaqListStore } from '../store';
import { extractPlaceholders } from '@/utils/placeholder';


export default /*@__PURE__*/_defineComponent({
  __name: 'FaqQuestionEdit',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;
const tableStore = useAsyncFaqListStore();
const isModalVisible = ref(false);
const answerText = ref('');
const isAnswerModified = ref(false); 
const initialAnswerText = ref('');
const showSuccessToast = ref(false);
const showErrorToast = ref(false);
const toastMessage = ref('');
const confirmedStatus = ref(Boolean(props.rowData.faq_question_confirmation) || false);
const isPlaceholderWarningVisible = ref(false); 
const placeholderWarningMessage = ref(''); 

const clickHandler = async (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (props.rowData.id) {
    // Обновление метки при входе на страницу
    if(!props.rowData.faq_question_viewed.data?.id){
      await createFaqViewed({ faq_question: { data: { id: props.rowData.id }}});
    }
    router.push(ENavy.faqQuestionEdit.replace(':id', props.rowData.id.toString()));
  }
};

const viewAnswer = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  answerText.value = props.rowData.answer || 'В вопросе нет ответа';
  initialAnswerText.value = answerText.value; 
  isModalVisible.value = true;
  isAnswerModified.value = false; 
};

const updateAnswer = async (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (!answerText.value.trim()) {
    await showNotification('error', 'Ошибка: ответ не может быть пустым', 3000);
    return;
  }

  if (props.rowData.id && answerText.value !== props.rowData.answer) {
    try {
      await updateFaqAnswer(props.rowData.id, { 
        question_text: props.rowData.question_text, 
        answer: answerText.value
      });

      props.rowData.answer = answerText.value;
      initialAnswerText.value = answerText.value; 
      isAnswerModified.value = false;

      await showNotification('success', `Запись ответа в вопросе: "${props.rowData.question_text}", успешно сохранена`, 3000);
    } catch (error: any) {
      await showNotification('error', `Ошибка при сохранении: ${error.requestId || error}`, 3000);
    }
  }
};

// удаление
const deleteButtonHandler = async (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (!props.rowData.id) {
    return;
  }

  const isApproved = confirm('Удалить вопрос?');
  if (!isApproved) {
    return;
  }

  try {
    // Удаление вопроса вместе с альтернативными если они есть
    const response = await deleteFaq(props.rowData.id);

    if (response) {
      router.push(ENavy.faqQuestions);
      showNotification('success', `Вопрос ${props.rowData.question_text} успешно удалён.`, 3000);
      await tableStore.getData();
    } else {
      showNotification('error', `Произошла ошибка, попробуйте еще раз.`, 3000);
    }
  } catch (err) {
    showNotification('error', `Ошибка ${err}.`, 3000);
  }
};

const closeModal = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (answerText.value !== initialAnswerText.value) {
    const confirmClose = confirm('Сохранить ответ перед закрытием?');
    if (confirmClose) {
      updateAnswer(new Event('click')).then(() => {
        isModalVisible.value = false;
      });
    } else {
      isModalVisible.value = false;
    }
  } else {
    isModalVisible.value = false;
  }
};

const isUpdating = ref(false); // Флаг блокировки, для ислючения ошибок при быстром переключение чекбокса

const onConfirmedStatusChange = async (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (isUpdating.value) return;
  isUpdating.value = true;

  const placeholdersFromAnswer = extractPlaceholders(props.rowData.answer);
  const placeholdersFromQuestion = extractPlaceholders(props.rowData.question_text);
  const uniquePlaceholders = [...new Set([...placeholdersFromAnswer, ...placeholdersFromQuestion])];

  if (uniquePlaceholders.length > 0) {
    const placeholdersList = await getPlaceholder({ keys: uniquePlaceholders });
    const existingKeys = placeholdersList.data.list.map((item: { key: string }) => item.key);
    const missingKeys = uniquePlaceholders.filter(key => !existingKeys.includes(key));

    const missingValues = placeholdersList.data.list
      .filter((item: { value: string }) => !item.value)
      .map((item: { key: string }) => item.key);

    const errors: string[] = [];


    if (missingValues.length > 0) {
      errors.push(`Следующие плейсхолдеры не имеют значения: ${missingValues.join(', ')}`);
    }

    if (missingKeys.length > 0) {
      errors.push(`Данных плейсхолдеров не существует: ${missingKeys.join(', ')}`);
    }

    if (errors.length > 0) {
      placeholderWarningMessage.value = `Вопрос не будет включен в экспорт!<br>${errors.join('<br>')}`;
      isPlaceholderWarningVisible.value = true;

      confirmedStatus.value = props.rowData.faq_question_confirmation?.confirmed || false;
      isUpdating.value = false;
      return;
    }
  }

  try {
    let updatedConfirmation = {
      ...props.rowData.faq_question_confirmation,
      confirmed: confirmedStatus.value
    };

    const payload: any = {
      question_text: props.rowData.question_text,
      answer: props.rowData.answer,
      faq_question_confirmation: updatedConfirmation,
      alternative_question: props.rowData.faq_question_variants,
    };

    if (props.rowData.faq_question_viewed?.data?.id) {
      payload.faq_question_viewed = true;
    }
    props.rowData.faq_question_confirmation = updatedConfirmation;
    const response = await updateFaq(props.rowData.id, payload);

    if (response) {
      await showNotification('success', `Запись "${props.rowData.question_text}" успешно ${confirmedStatus.value ? 'добавлена в экспорт' : 'добавлена в черновик'}`, 3000);
    }
  } catch (error: any) {
    await showNotification('error', `Ошибка при изменении статуса: ${error.requestId || error}`, 3000);
  } finally {
    const updatedFaqData = await fetchFaqData(props.rowData.id);
    if (updatedFaqData) {
      props.rowData.faq_question_confirmation = updatedFaqData;
    }
    isUpdating.value = false;
  }
};

const saveAndCloseModal = async () => {
  confirmedStatus.value = false;
  isPlaceholderWarningVisible.value = false;

  try {
    let updatedConfirmation = {
      ...props.rowData.faq_question_confirmation,
      confirmed: confirmedStatus.value
    };

    props.rowData.faq_question_confirmation = updatedConfirmation;

    const response = await updateFaq(props.rowData.id, {
      question_text: props.rowData.question_text,
      answer: props.rowData.answer,
      faq_question_confirmation: updatedConfirmation,
      alternative_question: props.rowData.faq_question_variants,
    });

    if (response) {
      await showNotification('success', `Запись "${props.rowData.question_text}" успешно сохранена как черновик`, 3000);
    }
  } catch (error: any) {
    await showNotification('error', `Ошибка при сохранении: ${error.requestId || error}`, 3000);
  }
};

const closeWarningModal = () => {
  isPlaceholderWarningVisible.value = false;
};

const fetchFaqData = async (id: number) => {
  try {
    const response = await getFaqItem(id); 
    return await response.faq_question_confirmation.data;
  } catch (error) {
    console.error('Ошибка при получении обновленных данных:', error);
    return null;
  }
};


watch(() => props.rowData.answer, (newAnswer) => {
  answerText.value = newAnswer || 'В вопросе нет ответа';
});

watch(answerText, () => {
  isAnswerModified.value = true; 
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("td", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          onClick: clickHandler,
          class: "btn btn-outline-secondary btn-icon",
          title: "Редактировать"
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("i", { class: "mdi mdi-pencil" }, null, -1)
        ])),
        _createElementVNode("button", {
          onClick: viewAnswer,
          class: "btn btn-outline-info btn-icon",
          title: "Просмотр ответа"
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("i", { class: "mdi mdi-eye" }, null, -1)
        ])),
        _createElementVNode("button", {
          onClick: deleteButtonHandler,
          class: "btn btn-outline-danger btn-icon",
          title: "Удалить вопрос"
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("i", { class: "fa fa-trash-o" }, null, -1)
        ])),
        _createElementVNode("label", {
          class: "switch",
          title: confirmedStatus.value ? 'Опубликован' : 'Черновик'
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((confirmedStatus).value = $event)),
            onChange: onConfirmedStatusChange
          }, null, 544), [
            [_vModelCheckbox, confirmedStatus.value]
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "slider round" }, null, -1))
        ], 8, _hoisted_3)
      ])
    ]),
    _createVNode(ToastMessage, {
      type: "success",
      message: toastMessage.value,
      isVisible: showSuccessToast.value
    }, null, 8, ["message", "isVisible"]),
    _createVNode(ToastMessage, {
      type: "error",
      message: toastMessage.value,
      isVisible: showErrorToast.value
    }, null, 8, ["message", "isVisible"]),
    (isModalVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h3", null, _toDisplayString(props.rowData.question_text), 1),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((answerText).value = $event)),
                id: "faq-answer",
                class: "form-control",
                style: {"resize":"vertical"},
                required: ""
              }, null, 512), [
                [_vModelText, answerText.value]
              ]),
              _createElementVNode("span", _hoisted_7, "Введено символов: " + _toDisplayString(answerText.value.length), 1)
            ]),
            _createElementVNode("div", { class: "modal-buttons" }, [
              _createElementVNode("button", {
                class: "btn btn-success margin-top-faq",
                onClick: updateAnswer
              }, "Сохранить"),
              _createElementVNode("button", {
                class: "btn btn-danger margin-top-faq",
                onClick: closeModal
              }, "Закрыть")
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(ModalWarning, {
      isVisible: isPlaceholderWarningVisible.value,
      message: placeholderWarningMessage.value,
      onSave: saveAndCloseModal,
      onClose: closeWarningModal,
      showSaveButton: true,
      showCloseButton: true
    }, null, 8, ["isVisible", "message"])
  ], 64))
}
}

})