import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-overlay-answer"
}
const _hoisted_2 = { class: "modal-content-answer" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "modal-buttons" }


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalWarning',
  props: {
    isVisible: {
        type: Boolean,
        required: true,
    },
    message: {
        type: String,
        required: true,
    },
    showSaveButton: {  
        type: Boolean,
        default: false,
    },
    showCloseButton: { 
        type: Boolean,
        default: false,
    },
},
  emits: ['save', 'close'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const onSave = () => emit('save');
const onClose = () => emit('close');

return (_ctx: any,_cache: any) => {
  return (__props.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Внимание!", -1)),
          _createElementVNode("p", { innerHTML: __props.message }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            (__props.showSaveButton)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn btn-success margin-top-faq",
                  onClick: onSave
                }, " Сохранить "))
              : _createCommentVNode("", true),
            (__props.showCloseButton)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "btn btn-danger margin-top-faq",
                  onClick: onClose
                }, " Закрыть "))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})