<script setup lang="ts">
import { computed, ref, watch, nextTick } from 'vue';
import { useNotification } from './notificationService';

const { notificationQueue } = useNotification();
const fadeOut = ref(false);
const currentNotification = computed(() => notificationQueue.value[0]); 
const isVisible = computed(() => currentNotification.value?.isVisible);

watch(currentNotification, async (newValue) => {
  if (newValue?.isVisible) {
    fadeOut.value = false;
    await nextTick();

    setTimeout(() => {
      fadeOut.value = true;
    }, newValue.duration - 200); 
  }
});
</script>

<template>
  <div 
    v-if="isVisible" 
    class="jq-toast-wrap top-right" 
    :class="[
      { 'fade-out': fadeOut },
      { 'success-toast': currentNotification?.type === 'success', 'error-toast': currentNotification?.type === 'error' }
    ]"
  >
    <div class="jq-toast-single jq-has-icon" 
         :class="{ 'jq-icon-success': currentNotification?.type === 'success', 'jq-icon-error': currentNotification?.type === 'error' }">
      <div class="toast-icon">
        <i :class="currentNotification?.type === 'success' ? 'mdi mdi-check-circle' : 'mdi mdi-close-circle-outline'"></i>
      </div>
      <div class="toast-text">
        <h2 class="jq-toast-heading">{{ currentNotification?.type === 'success' ? 'Success' : 'Ошибка' }}</h2>
        <p>{{ currentNotification?.message }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.jq-toast-wrap.top-right {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000001;
  max-width: 330px;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  opacity: 1;
  transform: translateY(0);
  gap: 10px;
  animation: fadeIn 0.5s ease-out; 
}

.jq-toast-wrap.fade-out {
  opacity: 0;
  transform: translateY(-20px);
}

.jq-toast-wrap.fade-in {
  opacity: 1;
  transform: translateY(0);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px); 
  }
  100% {
    opacity: 1;
    transform: translateY(0); 
  }
}

.jq-toast-single {
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.toast-icon {
  flex: 1;
  font-size: 24px;
  color: white;
  margin-right: 10px;
}

.toast-text {
  flex: 4;
}

.jq-toast-heading {
  margin: 0;
  font-size: 16px;
}

.jq-toast-single p {
  font-size: 14px;
  margin: 5px 0 0;
}

.success-toast .jq-toast-single {
  background-color: #28a745;
  color: white;
}

.error-toast .jq-toast-single {
  background-color: #dc3545;
  color: white;
}

.error-toast .toast-icon {
  color: white;
}

</style>