<script setup lang="ts">
import { provide, ref, watch } from 'vue';
import { defineComponent } from 'vue';
// import { createModerator, getList } from './bff-service'; // Импортируем функцию из BFF-сервиса.
// components
import {
    Card,
    CardTitle,
    MinWidthAndScroll,
    Table,
    TablePagination
} from '@/components/kit';
import { EmptyTr, Row as TableRow } from './components';
import AllPlaceholdersFilters from './components/AllPlaceholdersFilters.vue';
// store
import { isUserCanEditPlaceholder, isUserCanViewPlaceholder } from '@/store';
import { createPlaceholder } from './bff-service';
import { useAsyncPlaceholderAllListStore } from './store/FaqPlaceholderStore';
import InputPlaceHolderValue from './components/InputPlaceHolderValue.vue';
import PlaceholderEditCell from './components/PlaceholderEditCell.vue';

const tableStore = useAsyncPlaceholderAllListStore();

tableStore.columns = [
  { key: 'id', title: '#' },
  { key: 'key', title: 'Ключ', searchable: true, filterable: true },
  { key: 'value', title: 'Значение', searchable: true, filterable: true, component: InputPlaceHolderValue },
];

if(isUserCanEditPlaceholder()){
  tableStore.columns.push({ key: 'edit', title: 'Редактировать', component: PlaceholderEditCell });
}

provide('tableStore', tableStore);
tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
  tableStore.row = {};
}

tableStore.row.component = TableRow;

if (isUserCanViewPlaceholder()) {
    tableStore.getData();
}

const isCreatePlaceholderVisible = ref(false);

// данные в инпутах при добавление плейсхолдера
const newPlaceholder = ref({
    key: '',
    value: '',
    meta: '',
    status: true
});

const errorMessages = ref({
    key: ''
});

const placeholders = ref<string[]>([]);

watch(
  () => tableStore.data, // При загрузке data не успевает подгружаться, следим за обновлением
  (newData) => {
    placeholders.value = newData.map((placeholder) => placeholder.key);
  },
  { immediate: true } 
);

// Следим за изменением ключа и сбрасываем ошибку
watch(
  () => newPlaceholder.value.key,
  () => {
    errorMessages.value.key = ''; // Сбрасываем ошибку при изменении ключа
  }
);

const createNewPlaceholder = async () => {
  const response = await createPlaceholder(newPlaceholder.value);
  if (response && response.status === false) {
    errorMessages.value.key = 'Плейсхолдер с таким ключом уже существует';
    return; // Не обновляем таблицу
  }

  if (response && response.status === true) {
    newPlaceholder.value = {
      key: '',
      value: '',
      meta: '',
      status: true
    };
    isCreatePlaceholderVisible.value = false;
    tableStore.getData(); // Обновляем таблицу
  }
};

defineComponent({ name: 'AllPlaceholderTable' });
</script>

<template>
  <Card v-if="!isUserCanViewPlaceholder()" style="position:relative">
    <CardTitle>Нет прав на просмотр данных</CardTitle>
  </Card>

  <Card v-if="isUserCanViewPlaceholder()" style="position:relative">
    <CardTitle>Плейсхолдеры FAQ</CardTitle>
    <div v-if="isUserCanEditPlaceholder()" class="col-lg-6">
      <p>
        <button
          class="btn btn-inverse-primary btn-fw"
          @click="isCreatePlaceholderVisible = !isCreatePlaceholderVisible"
        >
        {{ isCreatePlaceholderVisible ? 'Закрыть' : 'Добавить плейсхолдер' }}
        </button>
      </p>

      <div v-if="isCreatePlaceholderVisible" class="mb-4">
        <div class="form-group">
          <label for="telegram_user_id">Ключ</label>
          <input
            type="text"
            id="telegram_user_id"
            class="form-control"
            v-model.trim="newPlaceholder.key"
            placeholder="Введите ключ плейсхолдера"
          />
          <span v-if="errorMessages.key" class="text-danger">
            {{ errorMessages.key }}
          </span>
        </div>
        <div class="form-group">
          <label for="description">Значение</label>
          <input
            type="text"
            id="description"
            class="form-control"
            v-model.trim="newPlaceholder.value"
            placeholder="Введите значение плейсхолдера"
          />
        </div>
        <button @click="createNewPlaceholder" class="btn btn-success" :disabled="!newPlaceholder.key.trim() || !newPlaceholder.value.trim()">
          Создать
        </button>
      </div>
    </div>

    <Row>
      <MinWidthAndScroll>
        <Table :use-store="useAsyncPlaceholderAllListStore">
          <AllPlaceholdersFilters />
        </Table>
      </MinWidthAndScroll>
    </Row>

    <TablePagination
      class="tab-content tab-transparent-content"
      :use-store="useAsyncPlaceholderAllListStore"
    />
  </Card>
</template>