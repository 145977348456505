import { get, post, put, del, postFormData } from "@/service/bffService";
import {
  TFaqGetListResDto, 
  TFaqItemResDto, 
  TFaqCategoryListDto,
  TFaqCreate,
  TFaqUpdate,
  TCategoriesGetListResDto,
  TCategoryPostedGetItemResDto,
  TFaqItemInvalideImport,
  TQuestionViewed,
  TPlaceholderGetListResDto,
  TPlaceholderPostedGetItemResDto,
  IPlaceholderResponse,
} from "./types/dto";
export { TBffResponseError } from '@/service/bffService';

export type TFaqSearchParams = {
  filter_id_question?: string;
	filter_category?: string;
	filter_question?: string;
  filter_confirmed?: string;
}

export type TCategoriesSearchParams = {
  name: string;
  description: string;
}

export type TPlaceholderSearchParams = {
  key: string;
  value: string;
}

// Faq list
export const getFaqQuestionsList = async (page: number, perPage: number, search?: TFaqSearchParams): Promise<TFaqGetListResDto> => {
  const searchParams = Object.entries(search || {}).map(filter=>`${filter[0]}=${encodeURI(filter[1])}`).join('&');

  return get<TFaqGetListResDto>(`/api/faq-pannel-questions?perPage=${perPage}&page=${page}&${searchParams}`);
}

// Faq list
export const getFaqPopulateAll = async (): Promise<any> => {
  return get<any>(`/api/faq-populate-questions`);
}

// Faq item
export const getFaqItem = async (id: number): Promise<TFaqItemResDto> => {
  return get<TFaqItemResDto>('/api/faq-edit-questions/' + id);
}

export const updateFaq = async (id: number, data: TFaqUpdate): Promise<any> => {
  return put<TFaqUpdate, { status: boolean }>('/api/faq-edit-questions/' + id, data);
}

export const updateFaqAnswer = async (id: number, data: TFaqUpdate): Promise<any> => {
  return put<TFaqUpdate, { status: boolean }>('/api/faq-answer/' + id, data);
}

export const createFaq = async (data: TFaqCreate): Promise<{ status: boolean }> => {
  return post<TFaqCreate, { status: boolean }>('/api/faq-create-questions', data);
};

export const createFaqViewed = async (data: TQuestionViewed): Promise<{ status: boolean }> => {
  return post<any, { status: boolean }>('/api/faq-create-viewed', data);
};

export const deleteFaq = async (id: number): Promise<{ status: boolean }> => {
  return del<{ status: boolean }>('/api/faq-edit-questions/' + id);
};

export const deleteFaqQuestions = async (ids: number[]): Promise<{ status: boolean }> => {
  return post('/api/faq-questions-delete', { ids });
};

// delete faq question variants
export const deleteFaqQuestionVariant = async (id: number): Promise<{ status: boolean }> => {
  return del<{ status: boolean }>('/api/faq-question-variants/' + id);
};

// Faq list
export const getFaqCategories = async (): Promise<TFaqCategoryListDto> => {
  return get<TFaqCategoryListDto>(`/api/faq-get-categories`);
}

// Faq category
export const getFaqCategory = async (page: number,perPage: number,search?: TCategoriesSearchParams): Promise<TCategoriesGetListResDto> => {
  const searchParams = Object.entries(search || {}).map(filter=>`${filter[0]}=${encodeURI(filter[1])}`).join('&');

  return get<TCategoriesGetListResDto>(`/api/faq-get-category?perPage=${perPage}&page=${page}&${searchParams}`);
};

export const createCategory = async (data: TCategoryPostedGetItemResDto): Promise<TCategoryPostedGetItemResDto> => {
  return post('/api/faq-get-category', data);
};

export const updateCategory = async (id: number, data: object): Promise<any> => {
  return put<any, object>('/api/faq-get-category/' + id, data);
}

export const deleteCategory = async (id: number): Promise<any> => {
  return del<any>(`/api/faq-get-category/` + id);
};

// Placeholder
export const getFaqPlaceholderPublicApi = async (page: number,perPage: number,search?: TPlaceholderSearchParams): Promise<TPlaceholderGetListResDto> => {
  const searchParams = Object.entries(search || {}).map(filter=>`${filter[0]}=${encodeURI(filter[1])}`).join('&');

  return get<TPlaceholderGetListResDto>(`/public-api/faq-placeholder?perPage=${perPage}&page=${page}&${searchParams}&keyAuth=baa2137bd6b9231a65144e94db94df7c53cf6a831ff6d33d6cfe41e3227d26aebcb44d1cdb3bc326b7cef317e14b6491678a544941225e163c001d213537310e87b49be93bdd48232051239e84d9cc787f34ef90f29dbd99e778044f4fda6afc03e0a4a7587dc088ced8a6b8173b6b9fda4eab523e53646b7712c5d414e200aa`);
};

export const createPlaceholder = async (data: TPlaceholderPostedGetItemResDto): Promise<TPlaceholderPostedGetItemResDto> => {
  return post('/api/placeholder-create', data);
};

export const getPlaceholder = async (data: { keys: string[] } ): Promise<any> => {
  return post('/api/placeholders', data);
};

export const updatePlaceholder = async (id: number, data: object): Promise<any> => {
  return put<any, object>('/api/placeholder-update/' + id, data);
}

export const deletePlaceholder = async (id: number): Promise<any> => {
  return del<any>(`/api/placeholder-delete/` + id);
};

// Отправка файла
export const uploadFile = async (file: File, categoryId: string, type: string): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('category', categoryId);
  formData.append('type', type)
  
  return postFormData('/api/faq/import', formData);
};

// faq data invalide import
export const getFaqInvalidImport = async (req_id: string): Promise<TFaqItemInvalideImport> => {
  return get<TFaqItemInvalideImport>('/api/faq-invalide-import/' + req_id);
}

export const getJsonCategory = async (category: number): Promise<any> => {
  return get<any>(`/public-api/faq?category=${category}&key=810fb4aac6802adac68f62478d3889dfc1a011d79e74915405a4b38bb2179b7f014d2863eb9c10881b3e754de3ec71f17aebf2d53c051b1dcfa8a86e8fba0845b5fe8903ebb24b36ff3a922a997fb35fff3309e7cc3bb2c2f9d54765ec3e96d55e20c0d8e5550d77aafbc9a8e25f793df60d89a1fbfcda926257e27902da567a`);
};

export const getJsonCategoryForEdit = async (category: number): Promise<any> => {
  return get<any>(`/api/faq-question-export-edit?category=${category}`);
};

export const getIdQuestionPlaceholder = async (placeholder: string): Promise<IPlaceholderResponse> => {
  return get<IPlaceholderResponse>(`/api/faq-question-id-placeholders/${placeholder}`)
}