<script setup lang="ts">
import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { isUserCanEditDictionary, isUserCanViewDictionary } from '@/store';
import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import { useRoute } from 'vue-router';
import { TJsonUseStore } from 'litsystems-vue-json-editor-dev/src';
import JsonViewer from 'litsystems-vue-json-editor-dev/src/JsonViewer.vue';
import { useDictionaryEditItemStore } from './store/dictionaryItemStore';
import InputTagDictionaryRegulars from './components/InputTagDictionaryRegulars.vue';
import InputTagDictionaryBanWord from './components/InputTagDictionaryBanWord.vue';
import InputTagDictionaryBanWordNickname from './components/InputTagDictionaryBanWordNickname.vue';
import { useDictionaryEditNicknameStore } from './store';

// store
const store = useDictionaryEditItemStore();
const storeNickname = useDictionaryEditNicknameStore();
const dealId = ref<number>(NaN);

var useDictionaryStore: TJsonUseStore['useStore'];
var useDictionaryStoreNickname: TJsonUseStore['useStore'];

const getUseStoreCb = (useStore: TJsonUseStore['useStore']) => {
  useDictionaryStore = useStore;

  const jsonStore = useStore();

  // устанавливаем расширения
  jsonStore.addExtensionComponent('dictionary-ban-word', InputTagDictionaryBanWord);
  jsonStore.addExtensionComponent('dictionary-input', InputTagDictionaryRegulars);

  jsonStore.updateExtensions({
    key: {
      'words': ['dictionary-ban-word'],
      'regulars': ['dictionary-input'],
    },
  });

  // устанавливаем классы
  jsonStore.updateClassNames({
    row: {
      key: {
        'regulars': "--requlars-container"
      }
    },
    viewer: {
      key: {
        'words': "--hidden-row",
        'regulars': "--hidden-row",
      }
    }
  });

  jsonStore.updateHiddens({
    parentKey: [''],

    keyException: [
      'words',
      'regulars',
    ]
  });

  // изначальная установка даннных
  jsonStore.setReference(store.dictionary);
}

const getUseStoreCbNickname = (useStore: TJsonUseStore['useStore']) => {
  useDictionaryStoreNickname = useStore;

  const jsonStore = useStore();

  // устанавливаем расширения
  jsonStore.addExtensionComponent('dictionary-ban-word-nickname', InputTagDictionaryBanWordNickname);

  jsonStore.updateExtensions({
    key: {
      'words': ['dictionary-ban-word-nickname'],
    },
  });

  // устанавливаем классы
  jsonStore.updateClassNames({
    viewer: {
      key: {
        'words': "--hidden-row",
      }
    }
  });

  jsonStore.updateHiddens({
    parentKey: [''],

    keyException: [
      'words',
    ]
  });

  // изначальная установка даннных
  jsonStore.setReference(storeNickname.dictionary);
}

const isStoreReady = ref(false);
const isStoreNicknameReady = ref(false);

onMounted(async () => {
  const route = useRoute();
  const deal = parseInt((route?.params?.id ?? '') + '', 10);
  const idDictionaryNickname = 2;

  if (Number.isInteger(deal)) {
    dealId.value = deal;
    await store.getDataById(deal); 
    isStoreReady.value = true; 
    await storeNickname.getDataById(idDictionaryNickname); 
    isStoreNicknameReady.value = true; 
  }

  moveRegularsContainer();
});

const moveRegularsContainer = () => {
  nextTick(() => {
    const regularsContainer = document.querySelector('.--requlars-container');
    const regulars = document.querySelector('.--regulars-inside');

    if (regularsContainer && regulars) {
      regulars.appendChild(regularsContainer);
    }
  });
};

watch(
  [() => store, () => storeNickname],
  async () => {
    if (isStoreReady.value && isStoreNicknameReady.value) {
      moveRegularsContainer();
    }
  },
  { deep: true, immediate: true }
);

onUnmounted(() => {
    store.$reset();
    storeNickname.$reset();
    useDictionaryStore?.().$reset();
    useDictionaryStoreNickname?.().$reset();
});

const saveDictionaryHandler = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  try {
    const dictionaryStore = useDictionaryStore();
    const referenceData = JSON.stringify(dictionaryStore.reference, null, 2);
    const parsedData = JSON.parse(referenceData);
    const dictionaryStoreNickname = useDictionaryStoreNickname();
    const referenceDataNickname = JSON.stringify(dictionaryStoreNickname.reference, null, 2);
    const parsedDataNickname = JSON.parse(referenceDataNickname);

    // Разделяем данные и обновляем два стора
    if (store.isUpdated) {
      store.updateData(parsedData);
    }
    if (storeNickname.isUpdated) {
      storeNickname.updateData(parsedDataNickname);
    }

  } catch (error) {
    console.error('Ошибка при сохранении изменений:', error);
  }
};
</script>

<template>
    <PageHeader>Изменить словарь</PageHeader>

    <Card v-if="!isUserCanViewDictionary()" style="position:relative">
        <CardTitle>Нет прав на просмотр данных</CardTitle>
    </Card>

    <Card v-if="isUserCanEditDictionary()" style="position:relative">
        <Loader v-if="store.$loading || storeNickname.$loading" />
        <template v-else-if="store.$error && storeNickname.$error">
            <h3>Произошла ошибка получения данных.</h3>
            <p><strong>Попробуйте перезагрузить страницу</strong>, если это не поможет <strong>обратитесь в
                    поддержку</strong>.
            </p>
            <p><strong>Данные об ошибке.</strong></p>
            <p><strong>Обозначение ошибки: </strong>{{ store.$error.error || storeNickname.$error.error }}</p>
            <p><strong>ID запроса: </strong>{{ store.$error.requestId || storeNickname.$error.error }}</p>
            <p><strong>Код ответа: </strong>{{ (store.$error.code || storeNickname.$error.code) ?? '' }}</p>
            <p>&nbsp;</p>
        </template>
        <template v-else>
            <div class="row">
                <p>Название словаря:</p>
                <CardTitle>Проверка спама в сообщениях</CardTitle>
            </div>

            <!-- Рендеринг редактора -->
            <JsonViewer :getUseStoreCb="getUseStoreCb" />
            <JsonViewer :getUseStoreCb="getUseStoreCbNickname"/>
            
            <button v-if="store.isUpdated || storeNickname.isUpdated" class="btn btn-inverse-primary btn-fw" @click="saveDictionaryHandler">Сохранить
                изменения</button>

        </template>
    </Card>
</template>
  

<style lang="scss">
.--requlars-container{
  margin: 0;
}
.title-price {
    margin-bottom: 1rem;
}

.--visible-row {
    margin-bottom: 1rem;
    display: initial !important;
}

.--hidden-row {
    display: none;
}</style>