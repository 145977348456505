import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "nav" }
const _hoisted_2 = ["aria-expanded"]
const _hoisted_3 = { class: "nav flex-column sub-menu" }
const _hoisted_4 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_5 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_6 = ["aria-expanded"]
const _hoisted_7 = { class: "nav flex-column sub-menu" }
const _hoisted_8 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_9 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_10 = {
  key: 2,
  class: "nav-item"
}
const _hoisted_11 = ["aria-expanded"]
const _hoisted_12 = { class: "nav flex-column sub-menu" }
const _hoisted_13 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_14 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_15 = {
  key: 2,
  class: "nav-item"
}
const _hoisted_16 = {
  key: 6,
  class: "nav-item unselectable"
}

import { defineComponent, reactive, ref } from 'vue';
import { useRoute } from 'vue-router'
// dev / prod mode
import { useNavyStore } from '@/store';
// routes
import { routeNames, TRoutes, cmiStatuses } from "@/router";


type TLiCanToggle = typeof liCanToggles[keyof typeof liCanToggles];

// подготавливаем объект с открывающимися элементами навигации

export default /*@__PURE__*/_defineComponent({
  __name: 'Sidebar',
  setup(__props) {

const devMode = process?.env?.NODE_ENV === "development";
// components
// store
defineComponent({ name: 'AppSidebar' });
const routes = ref<typeof routeNames>(routeNames);

// toogles
// чтобы добавить раскрывающееся меню в навигации
const liCanToggles = {
    cmi: 'cmi',
    admin: 'admin',
    telegramAdminPanel: 'telegramAdminPanel',
    faq: 'faq',
} as const;

const toggles = reactive<Record<TLiCanToggle, boolean>>(
    Object.fromEntries(
        Object.entries(liCanToggles)
            .map(item => [[item[1]], false])
    )
);

// store
const navyStore = useNavyStore();


// methods
const isNavyAllowed = (route: TRoutes) => {
    return Boolean(~navyStore.userNavyList.indexOf(route))
};

const toggle = (index: TLiCanToggle) => {
    toggles[index] = !toggles[index];
};

const isActive = (path: string | string[]) => {
    if (!Array.isArray(path)) {
        path = [path]
    }

    const route = useRoute();
    const routes = route.matched?.[0]?.name as string;
    return routes && ~path.indexOf(routes);
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", {
    id: "sidebar",
    class: _normalizeClass(['sidebar sidebar-offcanvas', _unref(navyStore).mobileNavyOpen ? 'active' : ''].join(' '))
  }, [
    _createElementVNode("ul", _hoisted_1, [
      _cache[18] || (_cache[18] = _createElementVNode("li", { class: "nav-item nav-category unselectable" }, " Main ", -1)),
      (isNavyAllowed(routes.value.dashboard))
        ? (_openBlock(), _createElementBlock("li", {
            key: 0,
            class: _normalizeClass(['nav-item unselectable', isActive(routes.value.dashboard) ? 'active' : ''].join(' '))
          }, [
            _createVNode(_component_router_link, {
              to: { name: routes.value.dashboard },
              class: "nav-link",
              "active-class": "active"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("span", { class: "icon-bg" }, [
                  _createElementVNode("i", { class: "mdi mdi-cube menu-icon" })
                ], -1),
                _createElementVNode("span", { class: "menu-title" }, "Dashboard", -1)
              ])),
              _: 1
            }, 8, ["to"])
          ], 2))
        : _createCommentVNode("", true),
      (isNavyAllowed(routes.value.cmi)|| isNavyAllowed(routes.value.cmiPost))
        ? (_openBlock(), _createElementBlock("li", {
            key: 1,
            class: _normalizeClass(['nav-item unselectable', isActive(routes.value.cmi) ? 'active' : ''].join(' '))
          }, [
            _createElementVNode("a", {
              "data-bs-toggle": "collapse",
              "aria-controls": "page-layouts",
              class: _normalizeClass(['nav-link', !toggles.cmi ? 'collapsed' : ''].join(' ')),
              "aria-expanded": toggles.cmi,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (toggle(liCanToggles.cmi)))
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("span", { class: "icon-bg" }, [
                _createElementVNode("i", { class: "mdi mdi-apps menu-icon" })
              ], -1),
              _createElementVNode("span", { class: "menu-title" }, "ОМС", -1),
              _createElementVNode("i", { class: "menu-arrow" }, null, -1)
            ]), 10, _hoisted_2),
            _createElementVNode("div", {
              id: "page-layouts",
              class: _normalizeClass(['collapse', toggles.cmi ? 'show' : ''].join(' '))
            }, [
              _createElementVNode("ul", _hoisted_3, [
                (isNavyAllowed(routes.value.cmi))
                  ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                      _createVNode(_component_router_link, {
                        to: { name: routes.value.cmi, params: { status: _unref(cmiStatuses).all } },
                        class: "nav-link",
                        "active-class": "active"
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" Список заявок ")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ]))
                  : _createCommentVNode("", true),
                (isNavyAllowed(routes.value.cmiPost))
                  ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                      _createVNode(_component_router_link, {
                        to: { name: routes.value.cmiPost, params: { status: _unref(cmiStatuses).all  } },
                        class: "nav-link",
                        "active-class": "active"
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" Почтовые отправки ")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ], 2))
        : _createCommentVNode("", true),
      (isNavyAllowed(routes.value.prices))
        ? (_openBlock(), _createElementBlock("li", {
            key: 2,
            class: _normalizeClass(['nav-item unselectable', isActive(routes.value.prices) ? 'active' : ''].join(' '))
          }, [
            _createVNode(_component_router_link, {
              to: { name: routes.value.prices },
              class: "nav-link",
              "active-class": "active"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createElementVNode("span", { class: "icon-bg" }, [
                  _createElementVNode("i", { class: "mdi mdi-square-edit-outline menu-icon" })
                ], -1),
                _createElementVNode("span", { class: "menu-title" }, "Изменение цен", -1)
              ])),
              _: 1
            }, 8, ["to"])
          ], 2))
        : _createCommentVNode("", true),
      ([routes.value.dictionary, routes.value.moderators, routes.value.channels].some(isNavyAllowed))
        ? (_openBlock(), _createElementBlock("li", {
            key: 3,
            class: _normalizeClass(['nav-item unselectable', [routes.value.dictionary, routes.value.moderators, routes.value.channels].some(isActive)? 'active' : ''].join(' '))
          }, [
            _createElementVNode("a", {
              "data-bs-toggle": "collapse",
              "aria-controls": "page-layouts",
              class: _normalizeClass(['nav-link', !toggles.telegramAdminPanel ? 'collapsed' : ''].join(' ')),
              "aria-expanded": toggles.telegramAdminPanel,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (toggle(liCanToggles.telegramAdminPanel)))
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("span", { class: "icon-bg" }, [
                _createElementVNode("i", { class: "mdi mdi-telegram menu-icon" })
              ], -1),
              _createElementVNode("span", { class: "menu-title" }, "Телеграм", -1),
              _createElementVNode("i", { class: "menu-arrow" }, null, -1)
            ]), 10, _hoisted_6),
            _createElementVNode("div", {
              id: "page-layouts",
              class: _normalizeClass(['collapse', toggles.telegramAdminPanel ? 'show' : ''].join(' '))
            }, [
              _createElementVNode("ul", _hoisted_7, [
                (isNavyAllowed(routes.value.channels))
                  ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                      _createVNode(_component_router_link, {
                        to: { name: routes.value.channels, params: { status: _unref(cmiStatuses).complete } },
                        class: "nav-link",
                        "active-class": "active"
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" Список чатов ")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ]))
                  : _createCommentVNode("", true),
                (isNavyAllowed(routes.value.dictionary))
                  ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                      _createVNode(_component_router_link, {
                        to: { name: routes.value.dictionary, params: { id: '1' } },
                        class: "nav-link",
                        "active-class": "active"
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode(" Изменить словарь ")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ]))
                  : _createCommentVNode("", true),
                (isNavyAllowed(routes.value.moderators))
                  ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                      _createVNode(_component_router_link, {
                        to: { name: routes.value.moderators, params: { id: '1' } },
                        class: "nav-link",
                        "active-class": "active"
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode(" Модераторы ")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ], 2))
        : _createCommentVNode("", true),
      ([routes.value.faqQuestions, routes.value.faqCategory, routes.value.faqPlaceholder].some(isNavyAllowed))
        ? (_openBlock(), _createElementBlock("li", {
            key: 4,
            class: _normalizeClass(['nav-item unselectable', [routes.value.faqQuestions, routes.value.faqCategory, routes.value.faqPlaceholder].some(isActive)? 'active' : ''].join(' '))
          }, [
            _createElementVNode("a", {
              "data-bs-toggle": "collapse",
              "aria-controls": "page-layouts",
              class: _normalizeClass(['nav-link', !toggles.faq ? 'collapsed' : ''].join(' ')),
              "aria-expanded": toggles.faq,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (toggle(liCanToggles.faq)))
            }, _cache[12] || (_cache[12] = [
              _createElementVNode("span", { class: "icon-bg" }, [
                _createElementVNode("i", { class: "mdi mdi-comment-question-outline menu-icon" })
              ], -1),
              _createElementVNode("span", { class: "menu-title" }, "FAQ", -1),
              _createElementVNode("i", { class: "menu-arrow" }, null, -1)
            ]), 10, _hoisted_11),
            _createElementVNode("div", {
              id: "page-layouts",
              class: _normalizeClass(['collapse', toggles.faq ? 'show' : ''].join(' '))
            }, [
              _createElementVNode("ul", _hoisted_12, [
                (isNavyAllowed(routes.value.faqQuestions))
                  ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                      _createVNode(_component_router_link, {
                        to: { name: routes.value.faqQuestions, params: { status: _unref(cmiStatuses).complete } },
                        class: "nav-link",
                        "active-class": "active"
                      }, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode(" Список вопросов ")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ]))
                  : _createCommentVNode("", true),
                (isNavyAllowed(routes.value.faqCategory))
                  ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                      _createVNode(_component_router_link, {
                        to: { name: routes.value.faqCategory, params: { status: _unref(cmiStatuses).complete } },
                        class: "nav-link",
                        "active-class": "active"
                      }, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createTextVNode(" Категории ")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ]))
                  : _createCommentVNode("", true),
                (isNavyAllowed(routes.value.faqPlaceholder))
                  ? (_openBlock(), _createElementBlock("li", _hoisted_15, [
                      _createVNode(_component_router_link, {
                        to: { name: routes.value.faqPlaceholder, params: { status: _unref(cmiStatuses).complete } },
                        class: "nav-link",
                        "active-class": "active"
                      }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode(" Плейсхолдеры ")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ], 2))
        : _createCommentVNode("", true),
      (isNavyAllowed(routes.value.reviews))
        ? (_openBlock(), _createElementBlock("li", {
            key: 5,
            class: _normalizeClass(['nav-item unselectable', isActive(routes.value.reviews) ? 'active' : ''].join(' '))
          }, [
            _createVNode(_component_router_link, {
              to: { name: routes.value.reviews },
              class: "nav-link",
              "active-class": "active"
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createElementVNode("span", { class: "icon-bg" }, [
                  _createElementVNode("i", { class: "mdi mdi-forum menu-icon" })
                ], -1),
                _createElementVNode("span", { class: "menu-title" }, "Отзывы", -1)
              ])),
              _: 1
            }, 8, ["to"])
          ], 2))
        : _createCommentVNode("", true),
      devMode
        ? (_openBlock(), _createElementBlock("li", _hoisted_16, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: { name: routes.value.kit }
            }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createElementVNode("span", { class: "icon-bg" }, [
                  _createElementVNode("i", { class: "mdi mdi-code-braces menu-icon" })
                ], -1),
                _createElementVNode("span", { class: "menu-title" }, "Dev KIT", -1)
              ])),
              _: 1
            }, 8, ["to"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})