import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table__td-faq-category" }
const _hoisted_2 = ["checked"]

import { ITableCellProps } from '@/components/Table';



export default /*@__PURE__*/_defineComponent({
  __name: 'FaqCheckoutDelete',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;

const handleCheckboxChange = (event: Event) => {
  props.rowData.checkDelete = (event.target as HTMLInputElement).checked;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      checked: _ctx.rowData.checkDelete,
      onChange: handleCheckboxChange
    }, null, 40, _hoisted_2)
  ]))
}
}

})