<script setup lang="ts">
import { computed, defineProps, ref } from 'vue';
import { useAsyncPlaceholderAllListStore } from '../store/FaqPlaceholderStore';
import { deletePlaceholder, getIdQuestionPlaceholder, updatePlaceholder } from '../bff-service';
import { ITableCellProps } from '@/components/Table';
import ModalWarning from './ModalWarning.vue';

const tableStore = useAsyncPlaceholderAllListStore();
const props = defineProps<ITableCellProps>();
const { rowData } = props;
const key = ref(props.rowData.key);
const value = ref(rowData.value);

const isApplyDisabled = computed(() => !rowData.value?.trim()); // Проверка на пустое значение
const isPlaceholderWarningVisible = ref(false);
const placeholderWarningMessage = ref('');
const faqIds = ref<number[]>([]);

const applyEditing = async (row: any) => {
  try {
    await updatePlaceholder(row.id, { key: row.key, value: row.value, meta: '' });
    await tableStore.getData();
    row.isEditing = false; 
  } catch (error) {
    console.error('Ошибка при обновлении плейсхолдера', error);
  }
};

const cancelEditing = (row: any) => {
  row.isEditing = false;
  row.key = key.value;
  row.value = value.value;
};

const deletePlaceholderById = async () => {
  const isApproved = window.confirm(`Удалить плейсхолдер "${props.rowData.key}"?`);
  if (!isApproved) return;

  const response = await getIdQuestionPlaceholder(props.rowData.key);
  if (response?.data && response.data.length > 0) {
    faqIds.value = response.data;
    placeholderWarningMessage.value = `Невозможно удалить, данный плейсхолдер используется в следующих вопросах: ${response.data.join(', ')}`;
    isPlaceholderWarningVisible.value = true;
    return;
  }

  await deletePlaceholder(props.rowData.id);
  await tableStore.getData();
};

const closeWarningModal = () => {
  isPlaceholderWarningVisible.value = false;
};

</script>

<template>
  <td class="edit-table-cell">
    <div class="edit-table-container">
      <button v-if="!rowData.isEditing" class="btn btn-outline-secondary btn-icon" title="Редактировать"
        @click="rowData.isEditing = !rowData.isEditing">
        <i class="mdi mdi-pencil"></i>
      </button>

      <div v-else class="d-flex gap-2 align-items-center">
        <button class="btn btn-outline-success btn-icon" title="Применить редактирование" @click="applyEditing(rowData)"
          :disabled="isApplyDisabled">
          <i class="mdi mdi-check"></i>
        </button>
        <button class="btn btn-outline-primary btn-icon" title="Отменить редактирование"
          @click="cancelEditing(rowData)">
          <i class="mdi mdi-close"></i>
        </button>
        <button class="btn btn-outline-danger btn-icon" title="Удалить" @click="deletePlaceholderById">
          <i class="fa fa-trash-o"></i>
        </button>
      </div>
    </div>
    <ModalWarning :isVisible="isPlaceholderWarningVisible" :message="placeholderWarningMessage"
      @close="closeWarningModal" :showCloseButton="true" />
  </td>
</template>
