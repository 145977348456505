<script setup lang="ts">
import { ref, defineComponent, onMounted, computed, provide } from 'vue';
import {
  Card,
  CardTitle,
  MinWidthAndScroll,
  Table,
  TablePagination,
} from '@/components/kit';
import { EmptyTr, Row as TableRow } from './components';
import { isUserCanEditFaq, isUserCanViewFaq } from '@/store';
import AllFaqQuestionsFilters from './components/AllFaqQuestionsFilters.vue';
import FaqQuestionEdit from './components/FaqQuestionEdit.vue';
import QuestionTdTable from './components/QuestionTd.vue';
import CategoryTd from './components/CategoryTd.vue';
import FaqCheckoutDelete from './components/FaqCheckoutDelete.vue'
import { useAsyncFaqListStore } from './store';
import { showNotification } from './components/notificationService';
import { getFaqCategories, uploadFile, deleteFaqQuestions, getFaqInvalidImport } from './bff-service';
import DotIndicator from './components/DotIndicator.vue';

const tableStore = useAsyncFaqListStore();
const showImport = ref(false);
const fileInput = ref<HTMLInputElement | null>(null);
const selectedFile = ref<File | null>(null);
const componentDataLoaded = ref<boolean>(false);
const selectedCategory = ref<string>('none');
const categoryList = ref([{ value: 'none', label: 'Выберите категорию' }]);
const isLoading = ref(false);
const isAllSelected = ref(false);
const fileName = ref('');
const fileType = ref<'csv' | 'json'>('csv');
let errorsCount = ref(0);
let requestId = '';

const fetchData = async () => {
  try {
    const resCategories = await getFaqCategories();
    categoryList.value = [
      { value: 'none', label: 'Выберите категорию' },
      ...resCategories.list.map(i => ({
        value: i.id + '',
        label: i.name
      }))
    ];
    componentDataLoaded.value = true;
  } catch (error) {
    console.error(error);
    alert('Произошла ошибка, попробуйте перезагрузить страницу.');
  }
};

onMounted(() => {
  fetchData();
});

tableStore.columns = [
  { key: 'faq_question_viewed', title: '', searchable: false, filterable: false, component: DotIndicator},
  { key: 'checkDelete', title: "Выбрать", searchable: false, filterable: false, component: FaqCheckoutDelete},
  { key: 'id', title: 'ID', searchable: true, filterable: true },
  { key: 'category', title: 'Категория', searchable: true, filterable: true, component: CategoryTd },
  { key: 'question_text', title: 'Вопрос', searchable: true, filterable: true, component: QuestionTdTable },
];

if (isUserCanEditFaq()) {
  tableStore.columns.push({
    key: 'confirmed',
    title: 'Редактирование',
    searchable: true,
    filterable: true,
    component: FaqQuestionEdit,
  });
}

provide('tableStore', tableStore);
tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
  tableStore.row = {};
}

tableStore.row.component = TableRow;

const handleFileUpload = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  if (files && files.length > 0) {
    selectedFile.value = files[0];
    fileName.value = selectedFile.value.name;
    const ext = selectedFile.value.name.split('.').pop()?.toLowerCase() || '';
    fileType.value = ext === 'csv' ? 'csv' : 'json';
    showNotification('success', `Файл '${selectedFile.value.name}' загружен`, 3000);
  }
};

const onDrop = (event: DragEvent) => {
  event.preventDefault();
  if (!event.dataTransfer || event.dataTransfer.files.length === 0) return;
  selectedFile.value = event.dataTransfer.files[0];
  showNotification('success', `Файл '${selectedFile.value.name}' загружен`, 3000);
};

const resetFileInput = () => {
  if (fileInput.value) {
    fileInput.value.value = '';
  }
};

const removeFile = () => {
  selectedFile.value = null;
  resetFileInput();
  showNotification('success', 'Файл удалён из импорта', 3000);
};

const onDragOver = (event: DragEvent) => {
  event.preventDefault();
};

const createOrUpdateFaqQuestions = async () => {
  if (!selectedFile.value) {
    showNotification('error', 'Нет файла для импорта', 3000);
    return;
  }

  isLoading.value = true; 

  try {
    const response = await uploadFile(selectedFile.value, selectedCategory.value, selectedFile.value.type);
    if (response.status) {
      const { created, updated, errorCount, req_id } = response; 
      errorsCount = errorCount;
      requestId = req_id;
      if (created > 0 || updated > 0) {
        showNotification('success', `Файл успешно импортирован. Создано: ${created}, Обновлено: ${updated}. \nОшибок при импорте: ${errorCount}`, 6000);
      } else {
        showNotification('error', 'Файл импортирован, но изменений не произошло.', 5000);
      }
      await tableStore.getData(); 
      resetFileInput();
      selectedFile.value = null;
    } else {
      showNotification('error', 'Ошибка при импорте файла. Проверьте файл и попробуйте снова.', 5000);
    }
  } catch (error) {
    showNotification('error', `Ошибка при загрузке файла, ${error}. Попробуйте еще раз!`, 3000);
  } finally {
    isLoading.value = false; 
  }
};

const transformDataToCSV = (data: any[]) => {
  const csvRows: string[] = [];

  data.forEach(item => {
    const id = item.id || '';
    const question = item.question_text || '';
    const answer = item.answer || '';

    const alternativeQuestions = item.alternative_question || [];
    const question2 = alternativeQuestions[0]?.variant_text || '';
    const question3 = alternativeQuestions[1]?.variant_text || '';

    const row = [id, question, question2, question3, answer].join(';');
    csvRows.push(row);
  });

  return csvRows.join('\n');
};

const transformDataToJSON = (data: any[]) => {
  return data.map(item => {
    const transformedItem: any = {
      id: item.id,
      question: item.question_text || '', 
    };

    if (item.alternative_question && item.alternative_question.length > 0) {
      item.alternative_question.forEach((altQuestion: any, index: number) => {
        transformedItem[`question${index + 2}`] = altQuestion.variant_text || '';
      });
    }
    transformedItem.answer = item.answer || '';

    return transformedItem;
  });
};

const downloadErrors = async (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
  if (!requestId) return;

  try {
    const response = await getFaqInvalidImport(requestId);
    if (response.data) {
      const dataArray = JSON.parse(response.data) as string[];
      const parsedData = dataArray.map(item => JSON.parse(item));

      let content: string, mimeType: string;

      if (fileType.value === 'csv') {
        content = transformDataToCSV(parsedData);
        mimeType = 'text/csv';
      } else {
        const transformedData = transformDataToJSON(parsedData);
        content = JSON.stringify(transformedData, null, 2);
        mimeType = 'application/json';
      }

      const blob = new Blob([content], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `errors_${fileName.value}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  } catch (error) {
    console.error('Ошибка при скачивании:', error);
    showNotification('error', 'Не удалось скачать файл с ошибками', 3000);
  }
};

const toggleAllQuestions = () => {
  tableStore.data.forEach((question) => {
    question.checkDelete = false;
  });
  isAllSelected.value = !isAllSelected.value;
};

const isAnyQuestionSelected = computed(() => {
  return tableStore.data.some((question) => question.checkDelete);
});

const deleteSelectedQuestions = async () => {
  const selectedQuestions = tableStore.data.filter((question) => question.checkDelete);

  if (selectedQuestions.length === 0) return;
  const confirmDelete = confirm('Вы уверены, что хотите удалить выбранные вопросы?');
  if (!confirmDelete) return;

  try {
    const response = await deleteFaqQuestions(selectedQuestions.map((question) => question.id));
    if (response.status) {
      showNotification('success', 'Вопросы успешно удалены', 3000);
      await tableStore.getData();
    } else {
      showNotification('error', 'Ошибка при удалении вопросов', 3000);
    }
  } catch (error) {
    showNotification('error', `Ошибка при удалении вопросов: ${error}`, 3000);
  }
};

onMounted(() => {
  tableStore.clearFilters();
  tableStore.getData();
});

defineComponent({ name: 'AllFaqTable' });
</script>

<template>
  <Card v-if="!isUserCanViewFaq()" style="position:relative">
    <CardTitle>Нет прав на просмотр данных</CardTitle>
  </Card>
  <Card v-else style="position:relative">
    <CardTitle>Список FAQ</CardTitle>
    <div class="col-lg-6 button-question-container">
      <a v-if="isUserCanEditFaq()" href="/faq-create/?isCreating=true">
        <button class="btn btn-inverse-primary btn-fw">Добавить вопрос</button>
      </a>
      <button v-if="isUserCanEditFaq()" class="btn btn-inverse-primary btn-fw" @click="showImport = !showImport">
        {{ showImport ? 'Закрыть импорт' : 'Импорт вопросов' }}
      </button>
      <button
        v-if="isAnyQuestionSelected"
        class="btn btn-danger btn-fw"
        @click="deleteSelectedQuestions"
      >
        Удалить выбранные
      </button>
      <button 
        v-if="isAnyQuestionSelected"
        class="btn btn-inverse-primary btn-fw" 
        @click="toggleAllQuestions">
          Отменить выбор
      </button>
    </div>
    <div v-if="showImport" class="col-lg-4 question-selected-inport">
      <select v-model="selectedCategory" class="form-control">
        <option v-for="(option, k) in categoryList" :key="k" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>

    <div v-if="showImport && selectedCategory !== 'none'" class="dropify-wrapper col-lg-4" @drop="onDrop"
      @dragover="onDragOver" @click="fileInput?.click()">
      <button v-if="selectedFile && !isLoading" class="remove-file-btn" @click.stop="removeFile">✖</button>
      <div v-if="isLoading" class="loading-content">
        <div class="dot-opacity-loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <p>Идет импорт, пожалуйста подождите!</p>
      </div>
      <template v-else>
        <p v-if="!selectedFile">
          Перетащите файл JSON или CSV сюда или <span class="file-upload">выберите файл</span>
        </p>
        <div v-else class="file-preview">
          <img v-if="selectedFile.name.endsWith('.json')" class="drag-and-drop_image" src="../assets/img/json.webp">
          <img v-else-if="selectedFile.name.endsWith('.csv')" class="drag-and-drop_image" src="../assets/img/csv.svg">
          <span>{{ selectedFile.name }}</span>
        </div>
      </template>
      <input ref="fileInput" type="file" accept=".json,.csv" @change="handleFileUpload" hidden />
    </div>

    <div v-if="selectedFile" class="col-lg-4 button-question-container">
      <button class="btn btn-success" @click="createOrUpdateFaqQuestions">
        Сохранить вопросы
      </button>
    </div>

    <div v-if="errorsCount > 0" class="col-lg-4">
      <p class="text-danger">{{ errorsCount }} вопроса(ов) не удалось импортировать <a type="button" class="text-download-error" @click="downloadErrors" >Скачать вопросы</a></p>
    </div>

    <Row>
      <MinWidthAndScroll>
        <Table :use-store="useAsyncFaqListStore">
          <AllFaqQuestionsFilters />
        </Table>
      </MinWidthAndScroll>
    </Row>

    <TablePagination class="tab-content tab-transparent-content" :use-store="useAsyncFaqListStore" />
  </Card>
</template>

<style scoped>
.text-download-error{
  text-decoration: none;
  color: #0062FF;
}

.button-question-container {
  display: flex;
  column-gap: 10px;
  margin-bottom: 1rem;
}

.button-question-container button {
  margin-right: 10px;
}

input[type="checkbox"] {
  cursor: pointer;
}

.question-selected-inport select {
  margin-bottom: 1rem;
  width: 50%;
  min-width: 230px;
}

.drag-and-drop_image {
  width: 32px;
}

.dropify-wrapper {
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #f9f9f9;
}

.dropify-wrapper:hover {
  border-color: rgba(100, 100, 100, 0.3);
}

.file-upload {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.file-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 100%;  
  overflow: hidden; 
}

.file-preview span {
  max-width: calc(100% - 40px);
  text-overflow: ellipsis;  
  overflow: hidden; 
  white-space: nowrap;
  display: inline-block;
}

.drag-and-drop_image {
  width: 32px;
  height: 32px; 
}

.remove-file-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: red;
}

.remove-file-btn:hover {
  color: darkred;
}

.file-icon {
  width: 40px;
  height: 40px;
}
</style>