<script setup lang="ts">
import { ITableCellProps } from '@/components/Table';
import { ref, watch } from 'vue';

const props = defineProps<ITableCellProps>();
const { rowData } = props;

const inputValue = ref(rowData.value);
const isError = ref(false);

watch(() => rowData.value, (newVal) => {
  inputValue.value = newVal;
  validateInput();
});

watch(inputValue, (newVal) => {
  rowData.value = newVal;
  validateInput();
});

function validateInput() {
  isError.value = inputValue.value.trim() === '';
}
</script>

<template>
  <td>
    <div v-if="!rowData.isEditing" class="d-flex gap-2 align-items-center">
      <span v-text="rowData.value" />
    </div>
    
    <div v-else class="d-flex gap-2 align-items-center">
      <input 
        class="category-input-table"
        v-model="inputValue"
        :class="{ 'is-invalid': isError }"
        @blur="validateInput"
        :placeholder="isError ? 'Поле не может быть пустым' : ''"
      />
    </div>
  </td>
</template>