import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock, toHandlers as _toHandlers, mergeProps as _mergeProps, vModelText as _vModelText, withDirectives as _withDirectives, isRef as _isRef, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = { class: "container-button-avatar" }
const _hoisted_3 = {
  key: 0,
  class: "change-info-container"
}
const _hoisted_4 = {
  "data-v-61dd7a3d": "",
  class: "nav-profile-img"
}
const _hoisted_5 = ["alt", "src"]
const _hoisted_6 = { class: "change-info" }
const _hoisted_7 = { class: "change-info-p" }
const _hoisted_8 = { class: "col-lg-6 textarea__faq-question" }
const _hoisted_9 = { class: "select-category faq-select" }
const _hoisted_10 = ["required"]
const _hoisted_11 = { class: "textarea__faq-question margin-top-faq" }
const _hoisted_12 = { class: "textarea__faq-question margin-top-faq" }
const _hoisted_13 = { class: "textarea__faq-question margin-top-faq" }
const _hoisted_14 = { class: "col-lg-6 textarea__faq-answer margin-top" }
const _hoisted_15 = { class: "textarea__count_char" }
const _hoisted_16 = { class: "char-counter" }
const _hoisted_17 = { class: "export-checkbox-container margin-top-faq" }
const _hoisted_18 = ["title"]
const _hoisted_19 = {
  key: 0,
  class: "btn btn-success btn-fw margin-top-faq"
}
const _hoisted_20 = {
  key: 1,
  class: "btn btn-success btn-fw margin-top-faq"
}

import { 
  computed, 
  onMounted, 
  onUnmounted, 
  ref, 
  watch
} from 'vue';
import { isUserCanViewFaq, isUserCanEditFaq, useUserStore } from '@/store';
import PageHeader from '@/components/kit/PageHeader.vue';
import Loader from '@/components/Loader.vue';
import Card from '@/components/kit/Card.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import { routeNames, cmiStatuses } from "@/router";
import { useRoute } from 'vue-router';
import SelectFaq from './components/SelectFaq.vue';
import Row from "@/components/kit/Row.vue";
import FormGroup from "@/components/kit/FormGroup.vue";
// store
import { useFaqEditItemStore } from './store/FaqEditStore';
// api
import {
  createFaq,
  updateFaq,
  deleteFaq,
  getFaqCategories,
  getPlaceholder,
} from './bff-service';
// router
import { ENavy, router } from '@/router';
import { showNotification } from './components/notificationService';
import ToastMessage from './components/ToastMessage.vue';
import ModalWarning from './components/ModalWarning.vue';
import defaultUserImage from '@/assets/img/user.svg';
import { extractPlaceholders } from '@/utils/placeholder';



export default /*@__PURE__*/_defineComponent({
  __name: 'FaqQuestion',
  setup(__props) {

// components
const isDevMode = new URL(window.location.toString()).searchParams.has('_dev'); // Отладочная информация

// store
const store = useFaqEditItemStore();
const pageId = ref<number>(NaN);

const selectedCategory = ref<string[]>([]);
const route = useRoute();
const isCreating = ref(route.query.isCreating === 'true' || false);
const answerLength = computed(() => store.answer.length);
let question_variant_one = ref<string>('');
let question_variant_two = ref<string>('');
let question_variant_one_id = ref<number | null>(null);
let question_variant_two_id = ref<number | null>(null);
const showSuccessToast = ref(false);
const showErrorToast = ref(false);
const toastMessage = ref('');
let confirmedStatus = ref(false);
const user = useUserStore();
let userNameLastUpdate = ref<string>('');
let lastTimeUpdateQuestion = ref<string>('');
const isPlaceholderWarningVisible = ref(false); 
const placeholderWarningMessage = ref(''); 

// категории и референсы
const categoryList = [] as {
  value: string;
  label: string;
}[];

const fetchData = async () => {
  try {
    // получаем список категорий и референсов 
    const {
      0: resCategories
    } = await Promise.all([
      getFaqCategories()
    ]);

    // заполняем категории
    resCategories.list.map(i=>{
      categoryList.push({
        value: i.id + '',
        label: i.name,
      })
    });

    // устанавливаем флаг загрузки данных страницы
    pageDataLoaded.value = true;
  } catch (error) {
    console.error(error);
    alert('Произошла ошибка, попробуйте перезагрузить страницу.');
  }
};

onMounted(() => {
  fetchData();
  if(!isCreating.value){
    const route = useRoute();
    const pageID = parseInt((route?.params?.id ?? '') + '', 10);
    if (Number.isInteger(pageID)) {
      pageId.value = pageID;
      store.getDataById(pageID);
    }
  }
});

onUnmounted(() => {
  if (!isCreating.value) {
    store.$reset();
  }
});

// ожидание загрузки страницы и всех данных
const pageLoaded = ref<boolean>(false);
const pageDataLoaded = ref<boolean>(false);

watch(
  [
    pageDataLoaded,
    ()=>store.$loading
  ],

  ({0: dataLoaded, 1: storeLoading})=>{

    if (dataLoaded && (!storeLoading || isCreating.value)) {
     
      // подготавливаем категории
      var faq_categories = store.faq_categories;
      if (Array.isArray(faq_categories)) {
        selectedCategory.value = Array.from(new Set([ 
          ...selectedCategory.value,
          ...faq_categories.map(i => i.id + '')
        ]));
      }

      // подготавливаем альтернативные варианты вопроса
      var faq_question_variants = store.faq_question_variants; 
      if (faq_question_variants.length > 0) {
        question_variant_one.value = faq_question_variants[0]?.attributes.variant_text;
        question_variant_one_id.value = faq_question_variants[0]?.id;
        question_variant_two.value = faq_question_variants[1]?.attributes.variant_text;
        question_variant_two_id.value = faq_question_variants[1]?.id;
      }

      confirmedStatus = ref(Boolean(store.faq_question_confirmation.data?.id) || false);

      // Подготовка времени и имени кто менял вопрос
      var faq_question_versions = store.faq_question_versions;
      if(faq_question_versions.length) {
        lastTimeUpdateQuestion.value = faq_question_versions[0]?.attributes.createdAt ?? "изменений не было";
        userNameLastUpdate =  faq_question_versions[0]?.attributes.users_permissions_user?.data?.attributes?.username ?? "";
      }

      pageLoaded.value = true
    }
  }
);

const lastTime = computed(() => {
  if (!lastTimeUpdateQuestion.value) return "Нет данных";

  const date = new Date(lastTimeUpdateQuestion.value);

  if (isNaN(date.getTime())) return "Не было изменений";

  return new Intl.DateTimeFormat("ru-RU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Moscow",
  }).format(date);
});

// сохранение и добавление
const submitFormHandler = async (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  const arePlaceholdersValid = await checkPlaceholders(confirmedStatus.value);
  if (!arePlaceholdersValid) {
    return; 
  }

  const requestData: any = {
    question_text: store.question_text,
    answer: store.answer,
    categories: [...selectedCategory.value].map(Number),
    alternative_question: [
      { id: question_variant_one_id.value, variant_text: question_variant_one.value },
      { id: question_variant_two_id.value, variant_text: question_variant_two.value }
    ],
    faq_question_viewed: true,
  };

  requestData.faq_question_confirmation = {
    id: store.faq_question_confirmation.data?.id,
    confirmed: String(confirmedStatus.value),
  };

  try {
    const response = isCreating.value ? await createFaq(requestData) : await updateFaq(pageId.value, requestData);

    if (response.status) {
      showNotification('success', `Вопрос ${store.question_text} успешно ${isCreating.value ? 'создан' : 'обновлён'}.`, 3000);
      router.push(ENavy.faqQuestions);
      store.isUpdated = false; 
    } else {
      showNotification('error', 'Произошла ошибка, попробуйте еще раз.', 3000);
    }
  } catch (error: any) {
    console.error(error);
    showNotification('error', `Ошибка ${error.requestId}.`, 3000);
  }
};

// проверка плейсхолдеров
const checkPlaceholders = async (isConfirmed: boolean): Promise<boolean> => {
  const placeholdersFromAnswer = extractPlaceholders(store.answer);
  const placeholdersFromQuestion = extractPlaceholders(store.question_text);
  const uniquePlaceholders = [...new Set([...placeholdersFromAnswer, ...placeholdersFromQuestion])];

  if (uniquePlaceholders.length === 0) {
    return true;
  }

  const placeholdersList = await getPlaceholder({ keys: uniquePlaceholders });
  const existingKeys = placeholdersList.data.list.map((item: { key: string }) => item.key);
  const missingKeys = uniquePlaceholders.filter(key => !existingKeys.includes(key));

  const missingValues = placeholdersList.data.list
    .filter((item: { value: string }) => !item.value)
    .map((item: { key: string }) => item.key);

  const errors: string[] = [];

  if (missingValues.length > 0) {
    errors.push(`Следующие плейсхолдеры не имеют значения: ${missingValues.join(', ')}`);
  }

  if (missingKeys.length > 0) {
    errors.push(`Данных плейсхолдеров не существует: ${missingKeys.join(', ')}`);
  }

  if (errors.length > 0) {
    const errorMessage = errors.join('<br>'); 

    if (isConfirmed) {
      placeholderWarningMessage.value = `Вопрос не будет включен в экспорт!<br>${errorMessage}`;
    } else {
      placeholderWarningMessage.value = errorMessage;
    }

    isPlaceholderWarningVisible.value = true;
    return false;
  }
  return true;
};

const saveAndCloseModal = async () => {
  confirmedStatus.value = false;
  isPlaceholderWarningVisible.value = false;

  const requestData: any = {
    question_text: store.question_text,
    answer: store.answer,
    categories: [...selectedCategory.value].map(Number),
    alternative_question: [
      { id: question_variant_one_id.value, variant_text: question_variant_one.value },
      { id: question_variant_two_id.value, variant_text: question_variant_two.value }
    ],
    faq_question_viewed: true,
    faq_question_confirmation: {
      id: store.faq_question_confirmation.data?.id,
      confirmed: 'false', 
    },
  };

  try {
    const response = isCreating.value ? await createFaq(requestData) : await updateFaq(pageId.value, requestData);

    if (response.status) {
      showNotification('success', `Вопрос ${store.question_text} успешно сохранён как черновик.`, 3000);
      router.push(ENavy.faqQuestions);
      store.isUpdated = false; // Сбрасываем флаг после успешного сохранения
    } else {
      showNotification('error', 'Произошла ошибка, попробуйте еще раз.', 3000);
    }
  } catch (error: any) {
    console.error(error);
    showNotification('error', `Ошибка ${error.requestId}.`, 3000);
  }
};

const closeWarningModal = () => {
  isPlaceholderWarningVisible.value = false;
}; 

// удаление
const deleteButtonHandler = async (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (!pageId.value) {
    return;
  }

  const isApproved = confirm('Удалить вопрос?');
  if (!isApproved) {
    return;
  }

  try {
    // Удаляем вопрос, удаление альтернативных вопросов на бэке
    const response = await deleteFaq(pageId.value);

    if (response.status) {
      router.push(ENavy.faqQuestions);
      showNotification('success', `Вопрос ${store.question_text} успешно удалён.`, 3000);
    } else {
      showNotification('error', `Произошла ошибка, попробуйте еще раз.`, 3000);
    }
  } catch (err) {
    showNotification('error', `Ошибка ${err}.`, 3000);
  }
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, null, {
      default: _withCtx(() => _cache[13] || (_cache[13] = [
        _createTextVNode("Редактирование FAQ")
      ])),
      _: 1
    }),
    (!_unref(isUserCanViewFaq)())
      ? (_openBlock(), _createBlock(Card, {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(CardTitle, null, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_unref(isUserCanEditFaq)() && (pageId.value || isCreating.value))
        ? (_openBlock(), _createBlock(Card, {
            key: 1,
            style: {"position":"relative"}
          }, {
            default: _withCtx(() => [
              (!pageLoaded.value)
                ? (_openBlock(), _createBlock(Loader, { key: 0 }))
                : (_unref(store).$error)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _cache[19] || (_cache[19] = _createElementVNode("h3", null, "Произошла ошибка получения данных.", -1)),
                      _cache[20] || (_cache[20] = _createElementVNode("p", null, [
                        _createElementVNode("strong", null, "Попробуйте перезагрузить страницу"),
                        _createTextVNode(", если это не поможет "),
                        _createElementVNode("strong", null, "обратитесь в поддержку"),
                        _createTextVNode(".")
                      ], -1)),
                      _cache[21] || (_cache[21] = _createElementVNode("p", null, [
                        _createElementVNode("strong", null, "Данные об ошибке.")
                      ], -1)),
                      _createElementVNode("p", null, [
                        _cache[15] || (_cache[15] = _createElementVNode("strong", null, "Обозначение ошибки: ", -1)),
                        _createTextVNode(_toDisplayString(_unref(store).$error.error), 1)
                      ]),
                      _createElementVNode("p", null, [
                        _cache[16] || (_cache[16] = _createElementVNode("strong", null, "ID запроса: ", -1)),
                        _createTextVNode(_toDisplayString(_unref(store).$error.requestId), 1)
                      ]),
                      _createElementVNode("p", null, [
                        _cache[17] || (_cache[17] = _createElementVNode("strong", null, "Код ответа: ", -1)),
                        _createTextVNode(_toDisplayString(_unref(store).$error.code ?? ''), 1)
                      ]),
                      _cache[22] || (_cache[22] = _createElementVNode("p", null, " ", -1)),
                      _createVNode(_component_router_link, {
                        to: { name: _unref(routeNames).faqQuestions, params: { status: _unref(cmiStatuses).all } }
                      }, {
                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                          _createTextVNode("Вернуться к списку вопросов.")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ], 64))
                  : (pageLoaded.value)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        (_unref(isDevMode))
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _cache[23] || (_cache[23] = _createElementVNode("p", null, "только для отладки:", -1)),
                              _createElementVNode("textarea", {
                                value: JSON.stringify(_unref(store), null, `\t`),
                                class: "form-control form-control-sm",
                                style: {"resize":"vertical"}
                              }, null, 8, _hoisted_1),
                              _cache[24] || (_cache[24] = _createElementVNode("p", null, null, -1))
                            ], 64))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(CardTitle, null, {
                            default: _withCtx(() => _cache[25] || (_cache[25] = [
                              _createTextVNode("Редактирование данных")
                            ])),
                            _: 1
                          }),
                          (!isCreating.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createElementVNode("div", _hoisted_4, [
                                  _createElementVNode("img", {
                                    style: {"width":"32px"},
                                    alt: _unref(user).name,
                                    src: _unref(user).avatar || _unref(defaultUserImage)
                                  }, null, 8, _hoisted_5)
                                ]),
                                _createElementVNode("div", _hoisted_6, [
                                  _createElementVNode("p", _hoisted_7, "Изменено: " + _toDisplayString(lastTime.value), 1),
                                  _createElementVNode("p", null, _toDisplayString(_unref(userNameLastUpdate)), 1)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("form", { onSubmit: submitFormHandler }, [
                          _createVNode(FormGroup, null, {
                            default: _withCtx(() => [
                              _createVNode(Row, { class: "tab-content tab-transparent-content" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("div", _hoisted_9, [
                                      _cache[26] || (_cache[26] = _createElementVNode("label", {
                                        class: "key-label",
                                        for: "category-list"
                                      }, "Категория", -1)),
                                      (pageDataLoaded.value)
                                        ? (_openBlock(), _createBlock(SelectFaq, {
                                            key: 0,
                                            id: "category-list",
                                            options: categoryList,
                                            multiple: true,
                                            modelValue: selectedCategory.value,
                                            "onUpdate:modelValue": [
                                              _cache[1] || (_cache[1] = ($event: any) => ((selectedCategory).value = $event)),
                                              _cache[2] || (_cache[2] = ($event: any) => (_unref(store).setUpdated()))
                                            ]
                                          }, {
                                            search: _withCtx(({ attributes, events }) => [
                                              _createElementVNode("input", _mergeProps({
                                                class: "vs__search",
                                                required: !selectedCategory.value.length
                                              }, attributes, _toHandlers(events, true), {
                                                onInput: _cache[0] || (_cache[0] = ($event: any) => (_unref(store).setUpdated()))
                                              }), null, 16, _hoisted_10)
                                            ]),
                                            _: 1
                                          }, 8, ["modelValue"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _createElementVNode("div", _hoisted_11, [
                                      _cache[27] || (_cache[27] = _createElementVNode("label", {
                                        class: "key-label margin-top-faq",
                                        for: "faq-question"
                                      }, "Вопрос", -1)),
                                      _withDirectives(_createElementVNode("textarea", {
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(store).question_text) = $event)),
                                        id: "faq-question",
                                        class: "form-control form-control-sm",
                                        style: {"resize":"vertical"},
                                        required: "",
                                        onInput: _cache[4] || (_cache[4] = ($event: any) => (_unref(store).setUpdated()))
                                      }, null, 544), [
                                        [_vModelText, _unref(store).question_text]
                                      ])
                                    ]),
                                    _createElementVNode("div", null, [
                                      _createElementVNode("div", _hoisted_12, [
                                        _cache[28] || (_cache[28] = _createElementVNode("label", {
                                          class: "key-label",
                                          for: "faq-question"
                                        }, "Альтернативный вопрос 1", -1)),
                                        _withDirectives(_createElementVNode("textarea", {
                                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(question_variant_one) ? (question_variant_one).value = $event : question_variant_one = $event)),
                                          id: "faq-answer",
                                          onInput: _cache[6] || (_cache[6] = ($event: any) => (_unref(store).setUpdated())),
                                          class: "form-control form-control-sm",
                                          style: {"resize":"vertical"}
                                        }, null, 544), [
                                          [_vModelText, _unref(question_variant_one)]
                                        ])
                                      ]),
                                      _createElementVNode("div", _hoisted_13, [
                                        _cache[29] || (_cache[29] = _createElementVNode("label", {
                                          class: "key-label",
                                          for: "faq-question"
                                        }, "Альтернативный вопрос 2", -1)),
                                        _withDirectives(_createElementVNode("textarea", {
                                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(question_variant_two) ? (question_variant_two).value = $event : question_variant_two = $event)),
                                          id: "faq-answer",
                                          onInput: _cache[8] || (_cache[8] = ($event: any) => (_unref(store).setUpdated())),
                                          class: "form-control form-control-sm",
                                          style: {"resize":"vertical"}
                                        }, null, 544), [
                                          [_vModelText, _unref(question_variant_two)]
                                        ])
                                      ])
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_14, [
                                    _createElementVNode("div", _hoisted_15, [
                                      _cache[30] || (_cache[30] = _createElementVNode("label", {
                                        class: "key-label",
                                        for: "faq-answer"
                                      }, "Ответ", -1)),
                                      _withDirectives(_createElementVNode("textarea", {
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(store).answer) = $event)),
                                        id: "faq-answer",
                                        class: "form-control form-control-sm",
                                        style: {"resize":"vertical"},
                                        required: "",
                                        onInput: _cache[10] || (_cache[10] = ($event: any) => (_unref(store).setUpdated()))
                                      }, null, 544), [
                                        [_vModelText, _unref(store).answer]
                                      ]),
                                      _createElementVNode("span", _hoisted_16, "Введено символов: " + _toDisplayString(answerLength.value), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_17, [
                                      _cache[32] || (_cache[32] = _createElementVNode("label", {
                                        class: "key-label",
                                        for: "faq-answer"
                                      }, "Экспорт", -1)),
                                      _createElementVNode("label", {
                                        class: "switch",
                                        title: _unref(confirmedStatus) ? 'Опубликован' : 'Черновик'
                                      }, [
                                        _withDirectives(_createElementVNode("input", {
                                          type: "checkbox",
                                          onInput: _cache[11] || (_cache[11] = ($event: any) => (_unref(store).setUpdated())),
                                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_isRef(confirmedStatus) ? (confirmedStatus).value = $event : confirmedStatus = $event))
                                        }, null, 544), [
                                          [_vModelCheckbox, _unref(confirmedStatus)]
                                        ]),
                                        _cache[31] || (_cache[31] = _createElementVNode("span", { class: "slider round" }, null, -1))
                                      ], 8, _hoisted_18)
                                    ])
                                  ])
                                ]),
                                _: 1
                              }),
                              (isCreating.value)
                                ? (_openBlock(), _createElementBlock("button", _hoisted_19, "Сохранить"))
                                : (_openBlock(), _createElementBlock("button", _hoisted_20, "Сохранить изменения"))
                            ]),
                            _: 1
                          })
                        ], 32),
                        (!isCreating.value)
                          ? (_openBlock(), _createBlock(FormGroup, { key: 1 }, {
                              default: _withCtx(() => [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-danger btn-icon-text faq-btn-danger",
                                  onClick: deleteButtonHandler
                                }, _cache[33] || (_cache[33] = [
                                  _createElementVNode("i", { class: "fa fa-trash-o" }, null, -1),
                                  _createTextVNode(" Удалить вопрос ")
                                ]))
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(ToastMessage, {
                          type: "success",
                          message: toastMessage.value,
                          isVisible: showSuccessToast.value
                        }, null, 8, ["message", "isVisible"]),
                        _createVNode(ToastMessage, {
                          type: "error",
                          message: toastMessage.value,
                          isVisible: showErrorToast.value
                        }, null, 8, ["message", "isVisible"]),
                        _createVNode(ModalWarning, {
                          isVisible: isPlaceholderWarningVisible.value,
                          message: placeholderWarningMessage.value,
                          onSave: saveAndCloseModal,
                          onClose: closeWarningModal,
                          showSaveButton: true,
                          showCloseButton: true
                        }, null, 8, ["isVisible", "message"])
                      ], 64))
                    : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
  ], 64))
}
}

})