// Извлечение плейсхолдеров из текста
export const extractPlaceholders = (text: string): string[] => {
    const regex = /{{(.*?)}}/g;
    const matches = text.match(regex);
    if (matches) {
        return matches
            .map(match => match.replace(/{{|}}/g, '').trim())
            .filter(placeholder => placeholder.length > 0);
    }
    return [];
};