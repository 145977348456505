<script setup lang="ts">
import { ref, computed } from 'vue';
import { TJsonExtensionProps } from 'litsystems-vue-json-editor-dev/src';
import { useDictionaryEditItemStore } from '../store';
import { NameKeys, nameKeysDictionary } from '../types';

const props = defineProps<TJsonExtensionProps>();

const editStore = useDictionaryEditItemStore();

const labelName = computed(() => {
  return nameKeysDictionary[props.propKey as keyof NameKeys] || props.propKey;
});

const inputValue = ref('');

const updateStore = () => {
  const jsonStore = props.useStore();
  jsonStore.setValueByKeys(
    [...props.parentKeys, props.propKey].slice(1),
    [...editStore.dictionary.regulars]
  );
};

const addTag = (tag: string) => {
  const newTag = tag.trim();
  if (newTag && !editStore.dictionary.regulars.includes(newTag)) {
    editStore.isUpdated = true;
    editStore.dictionary.regulars.push(newTag);
    updateStore();
  }
};

const handleInput = (e: KeyboardEvent) => {
  const input = e.target as HTMLTextAreaElement;

  if (e.key === 'Backspace' && !input.value.trim() && editStore.dictionary.regulars.length) {
    editStore.isUpdated = true;
    editStore.dictionary.regulars.pop();
    updateStore();
    return;
  }

  if (e.key === 'Enter') {
    e.preventDefault();
    e.stopPropagation();

    if (props.propKey === 'regulars') {
      addTag(input.value);
    } else {
      const newTags = input.value
        .split(',')
        .map((tag) => tag.trim())
        .filter((tag, index, self) =>
          tag && !editStore.dictionary.regulars.includes(tag) && self.indexOf(tag) === index
        );

      if (newTags.length) {
        editStore.isUpdated = true;
        editStore.dictionary.regulars.push(...newTags);
        updateStore();
      }
    }

    input.value = '';
    inputValue.value = '';
  }
};

const handleButtonClick = () => {
  if (inputValue.value.trim()) {
    addTag(inputValue.value);
    inputValue.value = '';
  }
};

const removeTag = (index: number) => {
  editStore.isUpdated = true;
  editStore.dictionary.regulars.splice(index, 1);
  updateStore();
};
</script>

<template>
  <label class="key-label margin-both">
    {{ labelName }}
  </label>

  <div class="tagsinput">
    <textarea v-model="inputValue" class="form-control"
      placeholder="Добавьте регулярное выражение (через запятую, нажмите Enter для добавления, либо по кнопке ниже)"
      @keydown="handleInput"></textarea>

    <button class="btn btn-success" @click="handleButtonClick" :disabled="!inputValue.trim()">Добавить регулярное
      выражение</button>

    <div v-for="(tag, index) in editStore.dictionary.regulars" :key="index" class="tag">
      <span>{{ tag }}</span>
      <a href="#" @click.prevent="removeTag(index)">×</a>
    </div>
  </div>
</template>