
<script setup lang="ts">
import { defineProps } from 'vue';
import { ITableCellProps } from '@/components/Table/types';

const props = defineProps<ITableCellProps>();
const notViewed = props.rowData.faq_question_viewed.data?.id;
</script>

<template>
  <td>
    <div v-if="!notViewed" class="dot-indicator sm bg-primary"></div>
  </td>
</template>