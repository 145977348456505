import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex gap-2 align-items-center"
}
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: 1,
  class: "d-flex gap-2 align-items-center"
}
const _hoisted_4 = ["placeholder"]

import { ITableCellProps } from '@/components/Table';
import { ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputPlaceHolderValue',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;
const { rowData } = props;

const inputValue = ref(rowData.value);
const isError = ref(false);

watch(() => rowData.value, (newVal) => {
  inputValue.value = newVal;
  validateInput();
});

watch(inputValue, (newVal) => {
  rowData.value = newVal;
  validateInput();
});

function validateInput() {
  isError.value = inputValue.value.trim() === '';
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", null, [
    (!_unref(rowData).isEditing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", {
            textContent: _toDisplayString(_unref(rowData).value)
          }, null, 8, _hoisted_2)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["category-input-table", { 'is-invalid': isError.value }]),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
            onBlur: validateInput,
            placeholder: isError.value ? 'Поле не может быть пустым' : ''
          }, null, 42, _hoisted_4), [
            [_vModelText, inputValue.value]
          ])
        ]))
  ]))
}
}

})