<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
// components
import Input from '@/components/kit/Input.vue';
// store
import { useAsyncFaqListStore } from '../store';
import { getFaqCategories } from '../bff-service';

const tableStore = useAsyncFaqListStore();
const componentDataLoaded = ref<boolean>(false);
const selectedCategoryName = ref<string>('');
const question_text = ref<string>('');
const idQeustion = ref<string>('');
const selectedStatusQuestion = ref<string>('_all');

const categoryList = [
  {
    value: '_all',
    label: 'Все'
  }
] as {
  value: string;
  label: string;
}[];

const statusList = [
  { value: '_all', label: 'Все' },
  { value: 'false', label: 'Опубликованные' },
  { value: 'true', label: 'Черновики' }
];

const fetchData = async () => {
  try {
    const resCategories = await getFaqCategories();
    resCategories.list.forEach(i => categoryList.push({
      value: i.id + '',
      label: i.name,
    }));

    componentDataLoaded.value = true;
  } catch (error) {
    console.error(error);
    alert('Произошла ошибка, попробуйте перезагрузить страницу.');
  }
};

// При монтирование грузим выбранную категорию
onMounted(() => {
  const savedCategory = localStorage.getItem('selectedCategory');
  if (savedCategory) {
    selectedCategoryName.value = savedCategory;
  } else {
    selectedCategoryName.value = '_all'; 
  }
  fetchData();
});

watch(selectedCategoryName, (newCategory) => {
  localStorage.setItem('selectedCategory', newCategory);
});

onUnmounted(() => {
  tableStore.$reset();
});

const isAllChecked = computed(() => {
  return tableStore.data.every(question => question.checkDelete);
});

const toggleAllCheckboxes = () => {
  const newValue = !isAllChecked.value;
  tableStore.data.forEach(question => {
    question.checkDelete = newValue;
  });
};

watch([idQeustion ,selectedCategoryName, question_text, selectedStatusQuestion], ({
  0: id,
  1: category,
  2: question,
  3: status
}) => {
  // Фильтр по id вопроса
  var filterById = [];
  if(id){
    filterById.push(id);
  }
  tableStore.filterBy(2, filterById);

  // фильтр по категории
  var filterByCategory = [];
  if (!category || category !== '_all') {
    filterByCategory.push(parseInt(category, 10));
  }
  tableStore.filterBy(3, filterByCategory);

  // фильтр по вопросу
  var filterByQuestion = [];
  if (question) {
    filterByQuestion.push(question);
  }
  tableStore.filterBy(4, filterByQuestion);

  // фильтр по статусу
  let filterByStatus = [];
  if (status === 'true') {
    filterByStatus.push('true');
  } else if (status === 'false') {
    filterByStatus.push('false');
  }
  tableStore.filterBy(5, filterByStatus);
});
</script>

<template>
  <tbody class="cmi-deals-all-filters filter-faq">
    <tr v-if="componentDataLoaded">
      <td colspan="1" />
      <td>
        <input 
          type="checkbox" 
          @change="toggleAllCheckboxes" 
          :checked="isAllChecked"
        >
      </td>
      <td class="faq__table-tag-filter filter_id-table-data">
        <span class="mdi mdi-magnify"></span>
        <Input v-model="idQeustion" type="search" />
      </td>
      <td class="faq__table-tag-category custom-select-wrapper">
        <select v-model="selectedCategoryName" class="form-control">
          <option v-for="(option, k) in categoryList" :key="k" :value="option.value">
            {{ option.label }}
          </option>
        </select>
      </td>
      <td class="faq__table-tag-filter faq__table-tag-question">
        <span class="mdi mdi-magnify"></span>
        <Input v-model="question_text" type="search" />
      </td>
      <td class="faq__table-tag-confirmation custom-select-wrapper">
        <select v-model="selectedStatusQuestion" class="form-control">
          <option v-for="(option, k) in statusList" :key="k" :value="option.value">
            {{ option.label }}
          </option>
        </select>
      </td>
    </tr>
    <tr v-else>
      <td colspan="4" class="faq__table-tag-filter">
        Загрузка фильтров...
      </td>
    </tr>
  </tbody>

  <tbody v-if="tableStore.loading" class="cmi-deals-all-filters">
    <tr>
      <td colspan="8">
        Загрузка:
      </td>
    </tr>
  </tbody>
</template>

<style lang="scss">

.faq__table-tag-filter {
  position: relative;

  .mdi-magnify {
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
    color: #505050;
    font-size: 18px;
    transition: opacity 0.3s ease-in-out;
    opacity: 1; 
  }

  &:has(input:focus), 
  &:has(input:not(:placeholder-shown)) {
    .mdi-magnify {
      opacity: 0; 
    }
  }
}


.faq__table-tag-filter:has(input:focus) .mdi-magnify {
  opacity: 0;
}

.custom-select-wrapper {
  position: relative;

  &::after {
    content: "▼"; 
    font-size: 12px;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #505050;
  }
}

.filter_id-table-data {
  width: 6%;
}

.faq__table-tag-category {
  width: 20%;
}

.faq__table-tag-question {
  width: 60%;
}

.faq__table-tag-confirmation {
  width: 14%;
}

</style>
