import { generateAsyncDataTableStore, definePromiseQueue } from '@/components/Table';
import { getFaqQuestionsList, TFaqSearchParams } from '../bff-service';
import { TFaqGetListResDto } from '../types/dto';

export type TFaqData = {
  id: number;
  category?: {id: number; name: string}[];
  question_text: string;
  answer: string;
  checkDelete: boolean;
  faq_question_viewed: { id: number };
};

const allListPromiseQueue = definePromiseQueue<TFaqGetListResDto>();

export const useAsyncFaqListStore = generateAsyncDataTableStore<TFaqData>({
  initialState: {
    loading: true,
},

dataGetter: async (useStore) => {
  const store = useStore();

  await allListPromiseQueue.wait(allListGetter, useStore)
    .then(response=>{
      store.data = response.list.map( question => {
        return {
          id: question.id,
          category: question.categories,
          question_text: question.question_text,
          answer: question.answer,
          faq_question_confirmation: question.faq_question_confirmation,
          faq_question_variants: question.faq_question_variants,
          checkDelete: false,
          faq_question_viewed: question.faq_question_viewed
        };
      });
      store.paginateTotal = response.paginate.filtered;
      store.paginateFiltered = response.paginate.filtered;
    })
    .catch(e=>{
      store.data = [];
      store.paginateTotal = 0;
    })
    .finally(()=>{
      store.loading = false;
    })
}
});

// получение списка заявок
const allListGetter = (): Promise<TFaqGetListResDto> => {
  const store = useAsyncFaqListStore();
  const search: TFaqSearchParams = {};

  if(store.filters?.[2]?.value[0]) {
    search.filter_id_question = store.filters[2].value[0]
  }
  if (store.filters?.[3]?.value[0]) {
    search.filter_category = store.filters[3].value[0]
  }
  if (store.filters?.[4]?.value[0]) {
    search.filter_question = store.filters[4].value[0]
  }
  if (store.filters?.[5]?.value[0]) {
    search.filter_confirmed = store.filters[5].value[0]
  }

  const paginateQuestion = store.paginatePerPage = 50;
  return getFaqQuestionsList(store.paginatePage, paginateQuestion, search);
}
